<div class="container is-max-desktop container-side-adjust">
  <app-post-header [title]="postHeader.title" [subtitle]="postHeader.subtitle" [date]="postHeader.date"
    [tag]="postHeader.tag"></app-post-header>
  <div class="post-content-container">
    <img src="../../../../../../assets/053121/02.png" title="ASP.NET Core Setup SignalR" class="img-responsive" />
    <div class="post-content-text">
      <p><a href="https://docs.microsoft.com/en-us/aspnet/core/signalr/introduction?view=aspnetcore-5.0"
          target="_blank">SignalR</a> was originally released way back the year 2013 and since then, this library helps
        a lot of ASP.NET
        developers to
        add 'real-time' functionality or feautue in their app whether a chat feature, uploading/downloading progress
        tracker, ticketing and much more. In a nutshell, it uses <a href="https://en.wikipedia.org/wiki/WebSocket"
          target="_blank">WebSockets</a>
        whenever is possible. For most applications, Microsoft recommends SignalR over raw WebSockets. You can find more
        detailed explanations here:
        <a href="https://docs.microsoft.com/en-us/aspnet/core/fundamentals/websockets?view=aspnetcore-5.0">"WebSockets
          support in ASP.NET Core"</a>
      </p>
      <p>Okay, enough with the intro, let's get started on how we can setup this in our ASP.NET Core application.</p>
      <p>I'm using ASP.NET Core 5 template here. But in case the 'Microsoft.AspNetCore.SignalR' isn't installed yet in
        your project, just manuall install it via Nuget Package Manager or console.</p>
      <div class="post-step">1.0 Setting up the hub</div>
      <p><strong><a href="https://docs.microsoft.com/en-us/aspnet/core/signalr/introduction?view=aspnetcore-5.0#hubs"
            target="_blank">SignalR Hubs</a></strong> acts like a communication bridge between clients and servers.
        In the server code, you define methods that are called by the client which is the 'consumer' and in the client
        code, you define methods that are called from the server.</p>
      <p>
        In this example, I created <code>MainHub</code> that handles all of the hubs. But I'd prefer you creating a
        custom hub name depending on it's functionalities and if you'll end up having multiple hubs.
      </p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>In the code above, <code>SendNotification</code> has a parameter which is the <code>message</code> where we can
        pass a data from server to the client consumer.</p>
      <div class="post-step">2.0 Add SignalR service in Startup.cs</div>
      <p>
        Go to <code>ConfigureServices</code> method and add this code: <code>services.AddSignalR();</code> just like the
        code below.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-step">3.0 Register the Hub Endpoint in Startup.cs</div>
      <p>
        This will serve as the webapi endpoint where the client can connect with.
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>If you access the url, you can expect the result like the photo shown below.</p>
      <img src="../../../../../../assets/053121/01.PNG" class="img-responsive" />
      <p>This means that you're endpoint is up and running and waiting for the client-side to access the hub.</p>
      <p>And that’s it. It’s very simple to set it up!</p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>