<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <img
    src="../../../../../../assets/013121/01.jpg"
    class="img-responsive"
    alt="Create Pagination in ASP.NET Core | Mark Deanil Vicente"
  />
  <div class="post-content-container">
    <div class="post-content-text">
      <p>
        I remember when I was just starting to develop an application. I covered
        the basic functionalities like the
        <code>Create, Read, Update & Delete</code>
        <a
          href="https://en.wikipedia.org/wiki/Create,_read,_update_and_delete"
          target="_blank"
          >(CRUD).</a
        >
        Part of the <code>"Read"</code> functionality is viewing all the list of
        the data that the user created. At first, everything seems fine but when
        the user put tons of data and they wanted to view them in the list,
        they're having hard time waiting for the list to be loaded. As a
        beginner, I wasn't aware that I made a mistake to not include the
        server-side pagination in my app!
      </p>
      <p>
        We should always consider to implement server-side pagination especially
        if we can assume that the data we'll be showing in the list will become
        tons and tons after some months or even years. You don't want the users
        wait for so long to render all the data you have in the database.
      </p>
      <p>
        Okay! enough with intro, let's implement the pagination in our ASP.NET
        Core Web API.
      </p>
      <div class="post-step">FTF (First Things First)</div>
      <p>
        I would assume that you already have an app connected to the database
        using Entity Framework (Core). If you don't have yet, you should check
        this
        <a
          href="https://docs.microsoft.com/en-us/aspnet/core/data/ef-mvc/intro?view=aspnetcore-5.0"
          target="_blank"
          >"Tutorial: Get started with EF Core in an ASP.NET MVC web app"</a
        >
        on how to setup your app locally.
      </p>
      <div class="post-step">Step 1: Create Generic Helper Class</div>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        The <code>pageSize</code> represents the size of the data that we want
        to fetch or data per page. Then we use the <code>Skip</code> and
        <code>Take</code> from LINQ to specify how many data to skip and to
        fetch.
        <code
          >source.Skip((pageIndex - 1) * pageSize).Take(pageSize).ToList()</code
        >. Read more about <code>Skip</code> and <code>Take</code>
        <a
          href="https://www.codingame.com/playgrounds/213/using-c-linq---a-practical-overview/skip-and-take"
          target="_blank"
        >
          here.</a
        >
      </p>
      <div class="post-step">Step 2: Consume it in your service logic</div>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-step">Step 3: Call it in your Controller</div>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        Your frontend should have a functionality that calls this controller
        endpoint when the user clicks the pagination page or the row per page.
      </p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>
