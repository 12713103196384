import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learn-how-to-be-a-bee',
  templateUrl: './learn-how-to-be-a-bee.component.html',
  styleUrls: ['./learn-how-to-be-a-bee.component.css']
})
export class LearnHowToBeABeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
