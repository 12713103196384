<div class="container is-max-desktop container-side-adjust">
  <app-post-header [title]="postHeader.title" [subtitle]="postHeader.subtitle" [date]="postHeader.date"
    [tag]="postHeader.tag"></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <p>Before we begin, let's discuss what is data seeding. <strong><a
            href="https://en.wikipedia.org/wiki/Database_seeding#:~:text=Database%20seeding%20is%20the%20initial,want%20to%20develop%20in%20future."
            target="_blank">Data seeding</a></strong> is the process of populating
        initial data to the database. This is a good option especially if you want to have an initial data stored in
        your database.
      </p>
      <p>In Entity Framework Core, you have 3 options to achieve it. These are: <i>Model seed data
        </i>, <i>Manual migration customization</i>, and <i>Custom initialization logic</i>. <a
          href="https://docs.microsoft.com/en-us/ef/core/modeling/data-seeding" target="_blank">source</a></p>
      <p>In this demo, we're going to choose the mixed <i>Model seed data</i> & <i>Custom initialization logic</i>
        approach because
        what we want to do is create a data seed during migration and when the app starts to run whether locally or in
        the deployment environment. </p>
      <div class="post-step">FTF (First Things First)</div>
      <div class="content">
        <ul>
          <li>
            Make sure you already prepared your <code>ASP.NET Core</code> application.
          </li>
          <li>
            Your app has <code>DbContext</code> and connected to the database.
          </li>
        </ul>
      </div>
      <p>For our example, let's use the <code>Person.cs</code> model.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <div class="post-step"> Override <code>OnModelCreating</code> in your <code>AppDbContext</code></div>
      <p><span class="number-step-bold">1.1</span> If you can't find <code>OnModelCreating</code> inside your
        <code>AppDbContext</code>, declare it there to
        override the function and put our data seed.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>As you can see, we hardcoded the <code>PersonId</code> so everytime the app runs the data seed, it has already
        a declared primary key.</p>
      <div class="post-step">2. Create migration file and update the database.</div>
      <p><span class="number-step-bold">2.1.</span> Run create migration file script:
        <code>dotnet ef migrations add CreateDataSeed</code>
      </p>
      <img src="../../../../../../assets/031321/01.PNG" class="img-responsive"
        alt="Data Seed in ASP.NET Core & EF Core | Mark Deanil Vicente" />
      <p><span class="number-step-bold">2.2.</span> Update database: <code>dotnet ef database update</code></p>
      <img src="../../../../../../assets/031321/02.PNG" class="img-responsive"
        alt="Data Seed in ASP.NET Core & EF Core | Mark Deanil Vicente" />
      <div class="post-step">3. Call the migration when the app starts to run via <code>Program.cs</code></div>
      <p>Alternatively, you can directly call the dbcontext and the migration inside the
        <code>public static void Main(string[] args)</code>, but in the following steps, we will create an extension
        method for <code>IHost</code> so we can just call it in our <code>Program.cs</code> after the
        <code>CreateHostBuilder(args).Build()</code>. This way, we can make our <code>Program.cs</code> cleaner and also
        for code readability.
      </p>
      <p><span class="number-step-bold">3.1</span> Install this nuget package <a
          href="https://www.nuget.org/packages/Microsoft.AspNetCore.Hosting.Abstractions/2.2.0"
          target="_blank">Microsoft.AspNetCore.Hosting.Abstractions</a></p>
      <code>Install-Package Microsoft.AspNetCore.Hosting.Abstractions -Version 2.2.0</code>
      <p><span class="number-step-bold">3.2</span> Create extension file and let's call it: <code>MigrationUtil</code>
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p><span class="number-step-bold">3.3</span> Call it in <code>Program.cs</code></p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <p>To test this, change the connection string then target a new database and run the application. (Make sure you
        still have the previous migrations like the CreateTable etc. to migrate the database properly)</p>
      <div class="post-step">Optional: Create extension method for <code>ModelBuilder</code></div>
      <p>If you don't want your <code>OnModelCreating</code> to be chunky with many <code>HasData</code> declarations in
        the future, then creating an extension method for <code>ModelBuilder</code> is a good option.</p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <div class="post-sub-step">Usage</div>
      <pre>
        <code [highlight]="code6"></code>
      </pre>
      That's it. I hope this post becomes helpful to you. Stay safe!
      <br />
      <app-post-footer></app-post-footer>
      <disqus [identifier]="'/' + postHeader.route"> </disqus>
    </div>
  </div>
</div>