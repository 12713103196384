import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector:
    'app-seed-users-and-roles-in-asp-net-core-with-entity-framework-core',
  templateUrl:
    './seed-users-and-roles-in-asp-net-core-with-entity-framework-core.component.html',
  styleUrls: [
    './seed-users-and-roles-in-asp-net-core-with-entity-framework-core.component.css',
  ],
})
export class SeedUsersAndRolesInAspNetCoreWithEntityFrameworkCoreComponent
  implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `builder.Entity<Role>().HasData(new List<Role>
{
  new Role {
    Id = 1, 
    Name = "Admin", 
    NormalizedName = "
    ADMIN"
  },
  new Role {
    Id = 2, 
    Name = "Staff", 
    NormalizedName = "STAFF"
  },
});`;

  code2 = `var hasher = new PasswordHasher<User>();
builder.Entity<User>().HasData(
    new User {
        Id = 1, // primary key
        UserName = "admin",
        NormalizedUserName = "ADMIN",
        PasswordHash = hasher.HashPassword(null, "temporarypass"),
        IsActive = true
    },
    new User {
        Id = 2, // primary key
        UserName = "staff",
        NormalizedUserName = "STAFF",
        PasswordHash = hasher.HashPassword(null, "temporarypass"),
        IsActive = true
    }
);

builder.Entity<UserRole>().HasData(
    new UserRole
    {
        RoleId = 1, // for admin username
        UserId = 1  // for admin role
    },
    new UserRole
    {
        RoleId = 2, // for staff username
        UserId = 2  // for staff role
    }
);`;

  code3 = `dotnet ef migrations add MigrateUserAndRoleSeed`;

  code4 = `dotnet ef database update`;

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(107);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
