<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">Introduction</div>
      <p>
        In building a big application using React.js, in order to maintain,
        properly manage and access different state changes across components,
        developers tend to use state management libraries like
        <a href="https://react-redux.js.org/" target="_blank">React Redux</a>,
        <a href="https://recoiljs.org/" target="_blank">Recoil</a>,
        <a href="https://jotai.org/" target="_blank">Jotai</a> etc. Most of
        React.js applications are using React Redux. But having Redux in your
        React.js brings some concerns like: "Configuring the store is too
        complicated", "many boilerplates" and "add a lot of packages to get
        Redux to do anything useful". If you're new in Redux, it would need you
        to take more time to spend learning the state management. People behind
        React Redux are aware of this. That's why they introduced a new option
        to handle your state management with ease. This is
        <a
          href="https://redux.js.org/redux-toolkit/overview#:~:text=Redux%20Toolkit%20is%20our%20official,recommend%20that%20you%20use%20it."
          target="_blank"
          >Redux Toolkit.</a
        >
      </p>
      <div class="post-sub-step">Why Redux Toolkit?</div>
      <p>
        On their site, they described it as their official, batteries-included
        toolset for efficient Redux development. And it's intended to be the
        standard way to write Redux logic and they definitely recommend this to
        use.
      </p>
      <p>
        <strong>Simple</strong> - Provides good defaults for store setup out of
        the box, and includes the most commonly used Redux addons built-in.
      </p>
      <p>
        <strong>Opinionated</strong> - Provides good defaults for store setup
        out of the box, and includes the most commonly used Redux addons
        built-in.
      </p>
      <p>
        <strong>Powerful</strong> - Takes inspiration from libraries like Immer
        and Autodux to let you write "mutative" immutable update logic, and even
        create entire "slices" of state automatically.
      </p>
      <p>
        <strong>Effective</strong> - Lets you focus on the core logic your app
        needs, so you can do more work with less code.
      </p>
      <a href="https://redux-toolkit.js.org/" target="_blank">source</a>
      <p>
        Alright, after introducing you about Redux Toolkit, I'll now proceed to
        setting up and usage of our redux toolkit in our component.
      </p>
      <div class="post-step">Step 1 - Setting up your React.JS project</div>
      <div class="post-sub-step">
        <p>1. Including or Adding Redux Toolkit in the project.</p>
      </div>
      <i>For new project</i>
      <pre>
          <code [highlight]="code1"></code>
      </pre>
      <i>Install the redux toolkit package</i>
      <p>
        NPM: <code>npm install @reduxjs/toolkit</code> or YARN:<code
          >yarn add @reduxjs/toolkit
        </code>
      </p>
      <p>
        Optionally, you can install other packages like axios because in our
        example application, I set up the axios and other packages there for UI.
      </p>
      <div class="post-step">Step 2 - Create your first slice</div>
      <p>
        A <strong>"Slice"</strong> in redux is a collection of redux reducer
        logic and actions. In our example, we created the
        <code>employeeSlice.ts</code> and it's called the API methods.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-step">Step 3 - Create store.ts</div>
      <p>
        The store.ts is where we configure and register our store and the slices
        that we need throughout the application.
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      On the codes above, this is where we register our reducer.
      <p>
        In my example, we're connecting to a web API endpoints and it has
        endpoints for getting basic employee details.
      </p>
      <div class="post-step">Step 4 - Configure index.tsx</div>
      <p>
        In the <code>index.tsx</code>, we add the components
        <code>Provider</code> as the parent component of our
        <code>App</code> component. This way, we can access the store throughout
        the app components.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-step">Step 5 - Setup the UI component</div>
      <p>
        For this example, I added some UI design for our single component. In
        real world scenario, we put the functionalities in separate components,
        but for the purpose of this blog, we put them into one.
      </p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <p>
        As you can see in the code above, we can easily access the state store
        by using <code>useSelector</code> and if we can call a method that's
        part of our slice, we can just
        <code>const dispatch = useAppDispatch();</code>
      </p>
      <br />
      <p>
        The full example and source code is available in this repository.
        <a
          href="https://github.com/deanilvincent/React.JS-ReduxToolkit-Typescript-CRUD-Sample"
          >https://github.com/deanilvincent/React.JS-ReduxToolkit-Typescript-CRUD-Sample</a
        >
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
