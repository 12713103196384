import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    window.location.href = "https://dnilvincent.net"

    this.titleService.setTitle('Home Page | Mark Deanil Vicente');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Welcome to my blog',
    });
  }
}
