import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-lazy-load-an-image-and-detect-when-its-loaded-in-vuejs',
  templateUrl: './lazy-load-an-image-and-detect-when-its-loaded-in-vuejs.component.html',
  styleUrls: ['./lazy-load-an-image-and-detect-when-its-loaded-in-vuejs.component.scss']
})
export class LazyLoadAnImageAndDetectWhenItsLoadedInVuejsComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  altImage = "Analyze Image in Vue.JS using Azure Computer Vision - dnilvincent.net"

  code1 = `<img src="/.." loading="lazy">`

  code2 = `<img src='fromSomewhere' loading="lazy" @load="onLoadImage" />`

  code3 = `<script setup>
import { ref } from "vue"

const isImageLoaded = ref(false)

const onLoadImage = () => {
    // when image is loaded, do something
    isImageLoaded.value = true
}
</script>

<template>
  <div v-if="!isImageLoaded">
      // Show something like loading image or skeleton
  </div>
  <img v-else loading="lazy" src="fromSomewhere" @load="onLoadImage" />
</template>
`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(130);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
