import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-add-image-analyzer-to-your-vuejs-using-azure-computer-vision',
  templateUrl: './add-image-analyzer-to-your-vuejs-using-azure-computer-vision.component.html',
  styleUrls: ['./add-image-analyzer-to-your-vuejs-using-azure-computer-vision.component.css']
})
export class AddImageAnalyzerToYourVuejsUsingAzureComputerVisionComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  altImage = "Analyze Image in Vue.JS using Azure Computer Vision - dnilvincent.net"

  code1 = `const key = "_YOUR_ENDPOINT_KEY";
const endpoint = "_YOUR_AZURECOMPUTERSERVICE_ENDPOINT_/vision/v3.2/analyze?";`

  code2 = `const analyzeImage = () => {
  let data = new FormData();
  data.append("blob", image, "file.png");

  const params = new URLSearchParams({
    // "Categories,Description,Color",
    visualFeatures: "Tags",
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Ocp-Apim-Subscription-Key": key,
    },
  };

  axios.post(\`$\{endpoint}\${params}\`, data, config).then((response) => {
    // response here
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    // do something after the request is finished
  });
};`

  code3 = `// Format tags for display
const formatTags = (tags: any) => tags.map((tag: any) => \`\${tag.name} (\${tag.confidence.toFixed(2) * 100}%)\`).join(", ")`

  code4 = `<script setup>
import { ref } from "vue";
import axios from "axios";

const key = "_YOUR_ENDPOINT_KEY";
const endpoint = "_YOUR_AZURECOMPUTERSERVICE_ENDPOINT_/vision/v3.2/analyze?";

const image = ref(null);
const isLoading = ref(false);
const result = ref(null);

const analyzeImage = () => {
  isLoading.value = true;
  let data = new FormData();
  data.append("blob", image, "file.png");

  const params = new URLSearchParams({
    // "Categories,Description,Color",
    visualFeatures: "Tags",
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Ocp-Apim-Subscription-Key": key,
    },
  };

  axios
    .post(\`\${endpoint}\${params}\`, data, config)
    .then((response) => {
      result.value = response.data
      // response here
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      // do something after the request is finished
      isLoading.value = false;
    });
};

const handleChangeImage = (e) => {
  if (e.target.files && e.target.files.length > 0)
    image.value = e.target.files[0];
};
</script>

<template>
  <div>
    <input type="file" accept="image/*" @change="handleChangeImage" />
    <button @onClick="analyzeImage">Analyze Image</button>
    <br />
    <p v-show="isLoading">Please wait...</p>
    <img
      v-show="image"
      src="{URL.createObjectURL(image)}"
      alt="preview"
    />
    <div v-show="result">
      <h3>Results:</h3>
      <p>{{ result }}</p>
    </div>
  </div>
</template>`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(129);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
