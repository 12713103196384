<div class="container is-max-desktop container-side-adjust">
  <app-post-header [title]="postHeader.title" [subtitle]="postHeader.subtitle" [date]="postHeader.date"
    [tag]="postHeader.tag"></app-post-header>
  <div class="post-content-container">
    <img src="../../../../../../assets/073121/01.jpg" title="Passing data between components in angular"
      class="img-responsive" />
    <div class="post-content-text">
      <div class="post-step">Option #1 - Parent To Child or More Child Components</div>
      <div class="post-sub-step">Parent Component</div>
      <p>In this example, what we want to update is pass a default value of <code>name</code> from parent to child</p>
      <code>parent.component.ts</code>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <br />
      <code>parent.component.html</code>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-sub-step">Child Component</div>
      <code>child.component.ts</code>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>In the above code, we use the <code>@Input()</code> decorator. This allows the parent to update the child
        depending on the data passed to it. By default, as you can see, <code>@Input() nameFromParent</code> has
        empty string value. During code runtime, this will be supplied based from the data that parent has. In our case,
        the <code>name</code> variable from parent.</p>
      <br />
      <code>child.component.html</code>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <img src="../../../../../../assets/073121/02.PNG" title="Passing data between components in angular"
        class="img-responsive" />
      <div class="post-step">Option #2 - Child or More Child to Parent Components</div>
      <p>In this scenario, what we want is to send a data from child to parent. For example, if a user clicks the button
        on a child component, then update the default <code>name</code> on the parent component.</p>
      <div class="post-sub-step">Child Component</div>
      <code>child.component.html</code>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <br />
      <code>child.component.ts</code>
      <pre>
        <code [highlight]="code6"></code>
      </pre>
      <p>Here, we use the <code>@Output()</code> decorator and instantiate an <code>EventEmitter</code>. Basically, we
        want to throw an event from child to parent. When parent detects an event from the child, then the parent will
        call a local event inside that component to able to update the selected variable. In our case, it's the
        <code>name</code>. You can see the updated code of parent component below.
      </p>
      <div class="post-sub-step">Parent Component</div>
      <code>parent.component.html</code>
      <pre>
        <code [highlight]="code7"></code>
      </pre>
      <br />
      <code>parent.component.ts</code>
      <pre>
        <code [highlight]="code8"></code>
      </pre>
      <img src="../../../../../../assets/073121/03.gif" title="Passing data between components in angular" />
      <p>That's it for the part 1. </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>