<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">Part Series</div>
      <p>
        <a [routerLink]="partSeries[1].url">{{ partSeries[1].title }}</a>
      </p>
      <p>
        <a [routerLink]="partSeries[2].url">{{ partSeries[2].title }}</a>
      </p>
      <div class="post-step">Prerequisites</div>
      <div>
        <li>Visual Studio and/or Visual Studio Code</li>
        <li>Dotnet installed (.NET Core or latest .NET)</li>
        <li>Azure Account with Azure AD access</li>
        <li>Configured machine that can create/run React.JS</li>
        <li>Postman (Optional)</li>
        <li>
          A cup of coffee ☕ or tea 🍵 and your choice of music 🎵 𝄞 🎸 𝄫 🎷🎶
          (Optional of course) 🎻
        </li>
      </div>
      <div class="post-step">Part 1 - Azure Active Directory Setup</div>
      <div class="post-sub-step">
        1. Create the Azure AD App Registration for Web API
      </div>
      <p>
        1.1 Go to Azure Active Directory -> App Registrations and click the
        <strong>New registration</strong>
      </p>
      <img
        src="../../../../../../assets/0322/1.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>
        1.2 Set the <strong>Application name</strong>,
        <strong>Supported account types</strong> and leave the
        <strong>Redirect URI</strong> as blank.
      </p>
      <div class="post-sub-step">
        2. Configure <strong>API permissions</strong>
      </div>
      <p>
        2.1 Go to your web api app registration and choose the API Permissions
        on the left side menu.
      </p>
      <p>
        2.2 By default, there's <strong>Microsoft Graph</strong> with
        <i>User.Read</i> permission.
      </p>
      <p>
        2.3 <strong>Grant admin consent for the selected directory</strong>.
        Once you granted the admin consent, then it will update the status on
        the right side of the record. (see the image below for reference).
      </p>
      <img
        src="../../../../../../assets/0322/2.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">
        3. Create API Scope (We'll need this to our client app)
      </div>
      <p>
        3.1 On the left side menu, go to <strong>Expose an API</strong> and
        click <strong>Set</strong>. This will show a modal of
        <i>Set the App ID URI</i>. Then click <strong>Save</strong>
      </p>
      <img
        src="../../../../../../assets/0322/3.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>
        3.2 Click the <strong>Add a scope</strong> and this will show a side
        modal. Put your scope name and other info., for this example, we'll make
        it simple with <strong>api.scope</strong>. Then click the
        <strong>Add scope</strong>
      </p>
      <img
        src="../../../../../../assets/0322/4.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">4. Create App Roles</div>
      <p>
        4.1 On the left menu, go to the <strong>App roles</strong> and click
        <strong>Create app role</strong>. For this blog, we'll add just one role
        for now which is the <strong>Admin</strong>. But let us allow the basic
        user to have an access to non-admin endpoints.
      </p>
      <li>
        <strong><i>Basic</i></strong> - An access to basic authorized endpoints
        <code>[Authorized]</code>.
      </li>
      <li>
        <strong><i>Admin</i></strong> - Will have an access both in Admin role
        and basic authorized endpoints
        <code>[Authorize(Roles = "Admin")]</code>.
      </li>
      <img
        src="../../../../../../assets/0322/5.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">
        5. Add Azure AD Users to the App Registration
      </div>
      <p>
        5.1 Go back to your Azure AD Directory and choose the
        <strong>Enterprise applications</strong> on the left side menu.
      </p>
      <p>
        5.2 Choose the desired application, and go to the
        <strong>Users and groups</strong>
      </p>
      <p>5.3 Click the <strong>Add user/group</strong></p>
      <img
        src="../../../../../../assets/0322/6.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>
        In our example, we assign <strong>Mark Vincent</strong> as the Default
        Access while the new user, which is <strong>Samp White</strong>, will
        have an admin access. This means, that
        <strong>Mark Vincent</strong> will have an access to the endpoints with
        <code>[Authorize]</code> while <strong>Samp White</strong> will have
        both access to <code>[Authorize]</code> and
        <code>[Authorize(Roles = "Admin")]</code> endpoints.
      </p>
      <p>
        That's it for our part 1. Part 2, we'll setup our ASP.NET Core Web API
        Application and configure it to use the settings like
        <code>ClientId</code>,<code>TenantId</code> etc.
      </p>
      <div class="post-sub-step">Summary</div>
      <p>
        On this blog, we worked on setting up our WebAPI App Registration using
        the Azure Active Directory. We also worked on setting up and adding user
        and role to our registered application.
      </p>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a class="button is-info" [routerLink]="partSeries[1].url"
            >NEXT Page</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
