import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost, PartSeries } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-integrate-reduxtoolkit-in-dynamic-reactjs-typescript-app',
  templateUrl: './integrate-reduxtoolkit-in-dynamic-reactjs-typescript-app.component.html',
  styleUrls: ['./integrate-reduxtoolkit-in-dynamic-reactjs-typescript-app.component.css']
})
export class IntegrateReduxtoolkitInDynamicReactjsTypescriptAppComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  partSeries: PartSeries[] | any = []

  code1 = `# Redux + Plain JS template
npx create-react-app my-app --template redux
  
# Redux + TypeScript template
npx create-react-app my-app --template redux-typescript`

  code2 = `
 import { createSlice } from "@reduxjs/toolkit";
 import { addEmployee, deleteEmployee, getEmployees, updateEmployee } from "./employeeApi";
 
 export const employeeSlice = createSlice({
     name: "employee",
     initialState: {
         list: {
             isLoading: false,
             status: "",
             values: []
         },
         save: {
             isSaving: false,
             isDeleting: false
         }
     },
     reducers: {
         clearSuccessMessage: (state, payload) => {
             // TODO: Update state to clear success message
         }
     },
     extraReducers: {
         [getEmployees.pending.type]: (state, action) => {
             state.list.status = "pending"
             state.list.isLoading = true
         },
         [getEmployees.fulfilled.type]: (state, { payload }) => {
             state.list.status = "success"
             state.list.values = payload
             state.list.isLoading = false
         },
         [getEmployees.rejected.type]: (state, action) => {
             state.list.status = "failed"
             state.list.isLoading = false
         },
         [addEmployee.pending.type]: (state, action) => {
             state.save.isSaving = true
         },
         [addEmployee.fulfilled.type]: (state, action) => {
             state.save.isSaving = false
         },
         [addEmployee.rejected.type]: (state, action) => {
             state.save.isSaving = false
         },
         [updateEmployee.pending.type]: (state, action) => {
             state.save.isSaving = true
         },
         [updateEmployee.fulfilled.type]: (state, action) => {
             state.save.isSaving = false
         },
         [updateEmployee.rejected.type]: (state, action) => {
             state.save.isSaving = false
         },
         [deleteEmployee.pending.type]: (state, action) => {
             state.save.isDeleting = true
         },
         [deleteEmployee.fulfilled.type]: (state, action) => {
             state.save.isDeleting = false
         },
         [deleteEmployee.rejected.type]: (state, action) => {
             state.save.isDeleting = false
         }
     }
 })
 
 export default employeeSlice.reducer`

  code3 = `
  import { configureStore } from "@reduxjs/toolkit";
  import { useDispatch } from "react-redux";
  import employeeSlice from "./features/Employee/employeeSlice";
  
  export const store = configureStore({
      reducer: {
          employee: employeeSlice
      },
  });
  
  // dispatch does not take types for thunks into account and thus the return type is typed incorrectly. Please use the actual Dispatch type from the store as decsribed in the documentation. Ref: https://stackoverflow.com/questions/63811401/property-then-does-not-exist-on-type-asyncthunkaction-redux-toolkit
  export type AppDispatch = typeof store.dispatch
  export const useAppDispatch = () => useDispatch<AppDispatch>()
  
  // Infer the \`RootState\` and \`AppDispatch\` types from the store itself
  export type RootState = ReturnType<typeof store.getState>`

  code4 = `
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );`

  code5 = `
  import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IEmployee, IEmployeeList } from "../../models/employee";
import { RootState, useAppDispatch } from "../../store";
import {
  getEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee,
} from "./employeeApi";
import moment from "moment";
import { Input, Checkbox, Button } from "../../components";
import { toast, ToastContainer } from "react-toastify";

export const Employee: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  const employeeList = useSelector(
    (state: RootState) => state.employee.list.values
  );
  const isLoadingTable = useSelector(
    (state: RootState) => state.employee.list.isLoading
  );
  const isSaving = useSelector(
    (state: RootState) => state.employee.save.isSaving
  );
  const isDeleting = useSelector(
    (state: RootState) => state.employee.save.isDeleting
  );

  const [employee, setEmployee] = useState<IEmployee>({
    employeeId: 0,
    name: "",
    birthday: moment(new Date()).format("YYYY-MM-DD"),
    isActive: false,
  });

  const [showValidation, setShowValidation] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setEmployee((prevState) => ({
      ...prevState,
      [name]: name === "isActive" ? checked : value,
    }));
  };

  const selectEmployee = (d: IEmployee) => {
    setShowValidation(false);
    setEmployee({
      employeeId: d.employeeId,
      name: d.name,
      isActive: d.isActive,
      birthday: moment(d.birthday).format("YYYY-MM-DD"),
    });
  };

  const removeEmployee = (id: number) => {
    if (id)
      dispatch(deleteEmployee(id))
        .unwrap()
        .then((response) => {
          toast.success(response);
          dispatch(getEmployees());
        })
        .catch((error) => {
          toast.error(error);
        });
  };

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (employee.name === "") {
      setShowValidation(true);
      return;
    }

    const action =
      employee.employeeId === 0
        ? addEmployee(employee)
        : updateEmployee(employee);

    dispatch(action)
      .unwrap()
      .then((response) => {
        toast.success(response);
        resetForm();
        dispatch(getEmployees());
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const resetForm = () => {
    setEmployee({
      employeeId: 0,
      name: "",
      isActive: false,
      birthday: moment(new Date()).format("YYYY-MM-DD"),
    });
    setShowValidation(false);
  };

  return (
    <>
      <div className="form-container">
        <h1 className="title">
          Employee &nbsp;
          <span className="tag is-link">{employeeList?.length}</span>
        </h1>
        <div className="card">
          <div className="card-content">
            <div className="content">
              <div className="columns">
                <div className="column is-4">
                  <Checkbox
                    title="Active"
                    name="isActive"
                    value={employee.isActive}
                    inputChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-4">
                  <Input
                    type="text"
                    title="Name"
                    name="name"
                    placeholder="Enter name here"
                    value={employee.name}
                    inputChange={handleInputChange}
                    showValidation={showValidation}
                    isRequired={true}
                  />
                </div>
                <div className="column is-4">
                  <Input
                    type="date"
                    title="Birthday"
                    name="birthday"
                    value={employee.birthday}
                    inputChange={handleInputChange}
                  />
                </div>
              </div>
              <Button
                type="is-success"
                loading={isSaving}
                title="Submit"
                onClick={submit}
                disabled={isSaving || isDeleting}
              />
              &nbsp;
              {employee.employeeId !== 0 && (
                <Button
                  title="Cancel"
                  onClick={resetForm}
                  disabled={isSaving || isDeleting}
                />
              )}
              <hr />
              {isLoadingTable && (
                <div className="has-text-centered">Fetching...</div>
              )}
              <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Active</th>
                      <th>Birthday</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeList?.map((d: IEmployeeList, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{d.name}</td>
                          <td>{d.isActive ? "Active" : "Inactive"}</td>
                          <td>{moment(d.birthday).format("MM/DD/YYYY")}</td>
                          <td>
                            <Button
                              type="is-warning"
                              title="Edit"
                              onClick={() => selectEmployee(d)}
                              disabled={isSaving || isDeleting}
                            />
                            &nbsp;
                            <Button
                              type="is-danger"
                              title="Delete"
                              loading={isDeleting}
                              onClick={() => removeEmployee(d.employeeId)}
                              disabled={isSaving || isDeleting}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer closeOnClick={true} />
      </div>
    </>
  );
};`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(122);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
      this.partSeries = data.partSeries
    }
  }
}
