import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-host-vuejs-on-iis',
  templateUrl: './host-vuejs-on-iis.component.html',
  styleUrls: ['./host-vuejs-on-iis.component.css'],
})
export class HostVuejsOnIisComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };
  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  code1 = `npm run build`;
  code2 = `<?xml version="1.0" encoding="utf-8"?>
  <configuration>
      <system.webServer>
          <rewrite>
              <rules>
                  <rule name="Handle History Mode and custom 404/500" stopProcessing="true">
                      <match url="(.*)" />
                      <conditions logicalGrouping="MatchAll">
                          <add input="{REQUEST_FILENAME}" matchType="IsFile" negate="true" />
                          <add input="{REQUEST_FILENAME}" matchType="IsDirectory" negate="true" />
                      </conditions>
                      <action type="Rewrite" url="/" />
                  </rule>
              </rules>
          </rewrite>
          <httpErrors>
              <remove statusCode="404" subStatusCode="-1" />
              <remove statusCode="500" subStatusCode="-1" />
              <error statusCode="404" path="/survey/notfound" responseMode="ExecuteURL" />
              <error statusCode="500" path="/survey/error" responseMode="ExecuteURL" />
          </httpErrors>
          <modules runAllManagedModulesForAllRequests="true"/>
      </system.webServer>
  </configuration>`;

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(104);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
