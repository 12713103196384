<div class="container is-max-desktop container-side-adjust">
  <app-post-header [title]="postHeader.title" [subtitle]="postHeader.subtitle" [date]="postHeader.date"
    [tag]="postHeader.tag"></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <p>Adding live chat feature in your website is made easy with using <a href="https://chatra.com/"
          target="_blank">Chatra</a>. Live chat or live support is commonly used in the website where the visitors can
        engage or ask some questions or additional info about the website and an agent or support will directly reply on
        your inquiry (if there's an available agent). Before we begin, let's discuss about what Chatra is.
        "Chatra is a live chat
        messenger app for your website. It is
        made to increase online sales but in a friendly, helpful way." - <a href="https://chatra.com/about-us/"
          target="_blank">source.</a> Chatra is also quite popular & available in different CMS (Content Management
        Systems) platforms like
        WordPress, WooCommerce, Shopify etc.</p>
      <p>Some of the good things I've found in using Chatra are: Easy to setup, a friendly admin portal where
        you can
        track all the chats made through your site and it's free if you're only using one agent account. If you want
        more than one agent, then you can visit: <a href="https://chatra.com/plans/" target="_blank">Chatra Plans &
          Features.</a></p>
      <p>Okay, enough with the intro and let's get started on how we can setup 3rd-party chat feature, powered by
        Chatra, in your Vue.JS, React.JS or Angular app.</p>
      <div class="post-step">FTF (First Things First)</div>
      <p>
        Begin we begin, make sure you already have a Chatra account, if not then you should create one first at<a
          href="https://app.chatra.io/" target="_blank">https://app.chatra.io/</a>.
      </p>
      <div class="post-step">1.0 Setup your chatra account. (Optional)</div>
      <p>If you want to add your chat name, logo and other additional settings, then you can go to <a
          href="https://app.chatra.io/settings/account" target="_blank">https://app.chatra.io/settings/account</a>.
      </p>
      <img src="../../../../../../assets/022721/01.PNG" class="img-responsive"
        alt="How to Add Chat Feature in Vue.JS, React.JS or Angular | Mark Deanil Vicente" />
      <div class="post-step">2.0 Connect the chat to your app.</div>
      <p>2.1 Go to <a href="https://app.chatra.io/settings/integrations/widget">Chat Widget section.</a> and copy all
        the codes. It should be something like this:</p>
      <pre><code [highlight]="code1"></code></pre>
      <div class="post-sub-step">For Vue.JS</div>
      <p>Go to <code>src/main.js</code> and paste the code (See sample below).</p>
      <pre><code [highlight]="code2"></code></pre>
      <div class="post-sub-step">For React.JS</div>
      <p>Go to <code>src/App.js</code> and paste the code (See sample below).</p>
      <pre><code [highlight]="code3"></code></pre>
      <div class="post-sub-step">For Angular</div>
      <p>Go to <code>src/index.html</code> and paste the code inside the header tag (See sample below).</p>
      <pre><code [highlight]="code4"></code></pre>
      <img src="../../../../../../assets/022721/02.PNG" class="img-responsive" />
      <img src="../../../../../../assets/022721/03.PNG" class="img-responsive" />
      <p>Chats can be viewed in the <a href="https://app.chatra.io/conversations/mychat" target="_blank">Chat page.</a>
      </p>
      <img src="../../../../../../assets/022721/04.PNG" class="img-responsive" />
      <br />
      <p> You can explore and adjust the settings of the chat widget at the portal based on your preferences. </p>
      <p>Well, that's it. I hope you like this post. </p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>