<div class="main-page-content-top-adjust"></div>
<br />
<h2 class="title is-2 title-style">{{title}}</h2>
<hr />
<h3 class="subtitle is-4 sub-title-style">{{subtitle}}</h3>
<span class="tag is-info">
  <i class="fas fa-calendar" aria-hidden="true"></i>
  <span class="date-section"></span>
  {{date}}
</span>
