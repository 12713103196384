<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">Enum Overview</div>
      <p>
        In our sample enum file, we created <code>TransactionStatus</code> where
        it has enum values and ids. We also used the
        <code>using System.ComponentModel.DataAnnotations</code> so we can use
        the attribute
        <code>[Display(Name = "")]</code>
      </p>
      <p>So let’s create our extension method.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <div class="post-step">Sample Usage</div>
      <p>Let’s try our extension method.</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>Then it will display <code>For Approval.</code></p>
      <a
        href="https://github.com/deanilvincent/EnumSampleCsharpConsoleApp"
        target="_blank"
        >Repo Link</a
      >
      <app-post-footer></app-post-footer>
      <disqus [identifier]="'/' + postHeader.route"> </disqus>
    </div>
  </div>
</div>
