<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">FTF (First Things First)</div>
      <div class="content">
        <ul>
          <li>
            Make sure you have an Azure Subscription to continue. If you don’t
            have a subscription, you can register your own free trial here
            <a href="https://azure.microsoft.com/en-us/free/" target="_blank"
              >https://azure.microsoft.com/en-us/free/</a
            >
          </li>
          <li>Visual Studio Code installed, of course amigo.</li>
          <li>Vue.JS Application</li>
          <li>A cup of coffee or tea ☕ (Optional)</li>
        </ul>
      </div>
      <div class="post-step">1. Create Your Azure App Service</div>
      <p>
        1.1 Go to the
        <a href="https://portal.azure.com" target="_blank"
          >https://portal.azure.com</a
        >
        and search for “App Services” on the search box. Choose the “Create app
        service” or click the “Add” button.
      </p>
      <img
        src="../../../../../../assets/072420/01.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>1.2 Configure the app service based on your preferences.</p>
      <img
        src="../../../../../../assets/072420/02.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>
        In this sample, I’m using Free F1 but, you can choose other plans
        depending on your preferred machine specifications.
      </p>
      <br />
      <p>
        1.3 Review and click “Create” when you’re done. Wait until it’s done
        then you can view your app service overview like the image below.
      </p>
      <img
        src="../../../../../../assets/072420/03.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <div class="post-step">
        2. Install Extension on your Visual Studio Code
      </div>
      <p>
        2.1 Open your Visual Studio Code and navigate to the “Extensions” tab.
      </p>
      <p>2.2 Look for “Azure App Service” then click “Install”.</p>
      <img
        src="../../../../../../assets/072420/04.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <div class="post-step">3. Deploying your Vue.JS App</div>
      <p>
        3.1 Build first your app using <code>npm run build command</code> and
        wait until it finishes.
      </p>
      <p>
        3.2 Click the Azure Extension logo on the left side and Sign In your
        Azure Account. This will open a browser tab where you should login your
        microsoft account with Azure Subscription.
      </p>
      <img
        src="../../../../../../assets/072420/05.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>
        After you successfully login your azure account, you’ll be able to see
        this.
      </p>
      <img
        src="../../../../../../assets/072420/06.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <div class="post-step">Option #1</div>
      <p>
        3.3.a Right click your app service, in my case, it’s the “myvuejsapp”
        then choose the “Deploy to Web App…” 3.4.b Browse the “dist” folder then
        select it.
      </p>
      <img
        src="../../../../../../assets/072420/07.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <div class="post-step">Option #2</div>
      <p>
        3.3.b Just simply right click the “dist” folder then choose the “Deploy
        to Web App…” and choose the app service you want to target. In my case,
        it’s the “myvuejsapp”
      </p>
      <img
        src="../../../../../../assets/072420/08.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>Wait until it finishes deploying then you’re done.</p>
      <img
        src="../../../../../../assets/072420/09.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>Lastly, visit your site.</p>
      <img
        src="../../../../../../assets/072420/10.png"
        class="img-responsive"
        alt="Deploy Vue.JS using VSCode | Mark Deanil Vicente"
      />
      <p>Voila! You have now vue.js running and hosted on Azure App Service.</p>
      <p>
        You can also visit this
        <a
          href="https://github.com/Microsoft/vscode-azureappservice/wiki"
          target="_blank"
          >WIKI</a
        >
        to learn more about this extension.
      </p>
      <app-post-footer></app-post-footer>
      <disqus [identifier]="'/' + postHeader.route"> </disqus>
    </div>
  </div>
</div>
