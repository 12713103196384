import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular',
  templateUrl: './how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular.component.html',
  styleUrls: ['./how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular.component.scss']
})
export class HowToAddLiveChatFeatureInVuejsReactjsOrAngularComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };
  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  code1 = `<!-- Chatra {literal} -->
  <script>
    (function(d, w, c) {
        w.ChatraID = 'YOUR_CHATRA_ID';
        var s = d.createElement('script');
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');
</script>
<!-- /Chatra {/literal} -->`;

  code2 = `import Vue from 'vue'
import App from './App.vue'

// CHATRA
(function (d, w, c) {
  w.ChatraID = 'YOUR_CHATRA_ID';
  var s = d.createElement('script');
  w[c] = w[c] || function () {
    (w[c].q = w[c].q || []).push(arguments);
  };
  s.async = true;
  s.src = 'https://call.chatra.io/chatra.js';
  if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
`

  code3 = `import './App.css';

// CHATRA
(function (d, w, c) {
  w.ChatraID = 'YOUR_CHATRA_ID';
  var s = d.createElement('script');
  w[c] = w[c] || function () {
    (w[c].q = w[c].q || []).push(arguments);
  };
  s.async = true;
  s.src = 'https://call.chatra.io/chatra.js';
  if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');

function App() {
  return (
    <div className="App">
      <header className="App-header">
        React.JS App
      </header>
    </div>
  );
}

export default App;
`

  code4 = `<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <title>NgApp</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" type="image/x-icon" href="favicon.ico">
  <!-- Chatra {literal} -->
  <script>
    (function (d, w, c) {
      w.ChatraID = 'YOUR_CHATRA_ID';
      var s = d.createElement('script');
      w[c] = w[c] || function () {
        (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');
  </script>
  <!-- /Chatra {/literal} -->
</head>

<body>
  <app-root></app-root>
</body>

</html>`

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(113);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
