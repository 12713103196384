<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">FTF (First Things First)</div>
      <div class="post-sub-step">Install MLflow</div>
      <p>
        What is MLFlow? “MLflow is an open source platform to manage the ML
        lifecycle, including experimentation, reproducibility, deployment, and a
        central model registry. MLflow currently offers four components: MLflow
        Tracking, MLflow Projects, MLflow Models & Model Registry” - Read more
        in
        <a href="https://mlflow.org/" target="_blank">MLflow.org</a>
      </p>
      <p>
        In this blog, we will only create a basic usage of MLflow where it logs
        a model, save and track.
      </p>
      <div class="post-step">Setup</div>
      <p>
        You can install MLflow in workspace library or you using python command.
        In this sample, we’re going to use the python notebook.
      </p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>And we import it.</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-step">Basic Usage</div>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>Use it</p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <p>You can view the Run tab to see the log model.</p>
      <img
        src="../../../../../../assets/062620/01.png"
        class="img-responsive"
        alt="Run Id using MlFlow in Databricks | Mark Deanil Vicente"
      />
      <img
        src="../../../../../../assets/062620/02.png"
        class="img-responsive"
        alt="Run Id using MlFlow in Databricks | Mark Deanil Vicente"
      />
      <p>
        In the above code, after we save our model, we use the
        <code>mlflow.tracking.MlflowClient()</code> class. What is
        MlFlow.Tracking? It is a module that provides Python CRUD interface to
        MLflow experiments and runs. <code>MlflowClient()</code> class has many
        methods, but what we need is the <code>get_run(id)</code> because it
        fetches the run from the backend store within the current notebook and
        the resulting run contains a collection of run metadata, as well as a
        collection of run parameters, tags, and metrics.
      </p>
      <p>
        I would also advice if you also add more mlflow tracking model logs like
        <code>Parameters</code>, <code>Metrics</code>, <code>Tags</code> and
        even notes in saving your model.
      </p>
      <p>
        Learn more from
        <a href="https://mlflow.org/docs/latest/index.html" target="_blank"
          >MLFlow Documetation</a
        >
      </p>
      <app-post-footer></app-post-footer>
      <disqus [identifier]="'/' + postHeader.route"> </disqus>
    </div>
  </div>
</div>
