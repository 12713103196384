import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-create-simple-custom-url-redirection-for-your-javascript-app',
  templateUrl:
    './create-simple-custom-url-redirection-for-your-javascript-app.component.html',
  styleUrls: [
    './create-simple-custom-url-redirection-for-your-javascript-app.component.css',
  ],
})
export class CreateSimpleCustomUrlRedirectionForYourJavascriptAppComponent
  implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `<Switch>
  <Route exact path="/login/r/:redirectionUrl">
    <Login />
  </Route>
</Switch>
`;

  code2 = `const routes = [
    { path: '/login/r/:redirectionUrl', component: Login }
]`;

  code3 = `const routes: Routes = [
    { path: "login/r/:redirectionUrl", component: LoginComponent }
]`;

  code4 = `import jwt_decode from 'jwt-decode'

function checkIfJwtTokenExpired(){
    const token = localStorage.getItem("token")
    if(token){
        const decodedToken = jwt_decode(token)
        if(decodedToken.exp < new Date().getTime() / 1000){
            window.location.href = \`/login/r/\${window.location.pathname}\`
        }
    }
}`;

  code5 = `window.location.href = \`/login/r/\${window.location.pathname}\``;

  code6 = `let redirectURLPath = window.location.pathname
// slice first 8 characters which is /login/r
redirectURLPath = redirectURLPath.slice(8)
  
// then we check if it's successfully login or not. (In depends on what logic you have)
if(successAuth || validAccess){
  // we get the value of redirectURLPath and redirect it to the page. In our example, it's the products page.
  
  // for Vue.JS 
  this.$router.push({path: redirectURLPath})
  // for React.JS
  <Redirect to={ { pathname: redirectURLPath } } />
  // for Angular
  this.router.navigate([redirectURLPath]) or this.router.navigateByUrl(redirectURLPath);
}`;

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(111);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
