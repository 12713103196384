import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-add-azure-cognitive-services-speech-to-text-to-your-vuejs-app',
  templateUrl: './add-azure-cognitive-services-speech-to-text-to-your-vuejs-app.component.html',
  styleUrls: ['./add-azure-cognitive-services-speech-to-text-to-your-vuejs-app.component.css']
})
export class AddAzureCognitiveServicesSpeechToTextToYourVuejsAppComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `const sdk = require("microsoft-cognitiveservices-speech-sdk");`

  code2 = `const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

// Create the speech recognizer.
let speechRecognizer = new sdk.SpeechRecognizer(speechConfig);`

  code3 = `const speechToText = () => {
    speechRecognizer.recognizeOnceAsync((result) => {
      switch (result.reason) {
        case sdk.ResultReason.RecognizedSpeech:
          text.value(result.text);
          break;
        case sdk.ResultReason.NoMatch:
          console.log("NOMATCH: Speech could not be recognized.");
          break;
        case sdk.ResultReason.Canceled:
          const cancellation = sdk.CancellationDetails.fromResult(result);
          console.log(\`CANCELED: Reason=\${cancellation.reason}\`);

          if (cancellation.reason === sdk.CancellationReason.Error) {
            console.log(\`CANCELED: ErrorCode=\${cancellation.ErrorCode}\`);
            console.log(\`CANCELED: ErrorDetails=\${cancellation.errorDetails}\`);
            console.log(
              "CANCELED: Did you set the speech resource key and region values?"
            );
          }
          break;
      }
      speechRecognizer.close();
    });
};`

code4 = `<template>
<div>
  <input v-model="text" />
  <button @click="speechToText()">Run Speech to Text</button>
</div>
</template>

<script setup>
// call the package
import { ref } from "vue";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

// Create the speech recognizer.
let speechRecognizer = new sdk.SpeechRecognizer(speechConfig);

const text = ref("");

const speechToText = () => {
 speechRecognizer.recognizeOnceAsync((result) => {
   switch (result.reason) {
     case sdk.ResultReason.RecognizedSpeech:
       text.value(result.text);
       break;
     case sdk.ResultReason.NoMatch:
       console.log("NOMATCH: Speech could not be recognized.");
       break;
     case sdk.ResultReason.Canceled:
       const cancellation = sdk.CancellationDetails.fromResult(result);
       console.log(\`CANCELED: Reason=\${cancellation.reason}\`);

       if (cancellation.reason === sdk.CancellationReason.Error) {
         console.log(\`CANCELED: ErrorCode=\${cancellation.ErrorCode}\`);
         console.log(\`CANCELED: ErrorDetails=\${cancellation.errorDetails}\`);
         console.log(
           "CANCELED: Did you set the speech resource key and region values?"
         );
       }
       break;
   }
   speechRecognizer.close();
 });
};
</script>`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(127);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
