import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-add-azure-cognitive-services-text-to-speech-to-your-vuejs-app',
  templateUrl: './add-azure-cognitive-services-text-to-speech-to-your-vuejs-app.component.html',
  styleUrls: ['./add-azure-cognitive-services-text-to-speech-to-your-vuejs-app.component.css']
})
export class AddAzureCognitiveServicesTextToSpeechToYourVuejsAppComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `import * as sdk from "microsoft-cognitiveservices-speech-sdk"`

  code2 = `// Text to Speech Config
const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
// The language of the voice that speaks.
speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";`

  code3 = `const textToSpeech = () => {
  let synthesizer = new sdk.SpeechSynthesizer(speechConfig);
  synthesizer.speakTextAsync(
    "Enter your text here.",
      function (result) {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("synthesis finished.");
        } else {
          console.error(
            \`Speech synthesis canceled: \${result.errorDetails}. Did you set the speech resource key and region values?\`
          );
        }
        synthesizer.close();
        synthesizer = null;
        isLoading.value = false;
      },
      function (err) {
        isLoading.value = false;
        console.trace(\`Error: \${err}\`);
        synthesizer.close();
        synthesizer = null;
      }
    );
};`

  code4 = `<template>
  <div>
    <input v-model="text" />
    <p v-if="isLoading">Loading...</p>
    <button v-else @click="textToSpeech()">Run Text to Speech</button>
  </div>
</template>

<script setup>
// call the package
import { ref } from "vue";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// Text to Speech Config
const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
// The language of the voice that speaks.
speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";

const text = ref("");
const isLoading = ref(false);

const textToSpeech = () => {
  if (text.value) {
    // Create the speech synthesizer.
    let synthesizer = new sdk.SpeechSynthesizer(speechConfig);
    isLoading.value = true;
    synthesizer.speakTextAsync(
      text.value,
      function (result) {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("synthesis finished.");
        } else {
          console.error(
            \`Speech synthesis canceled: \${result.errorDetails}. Did you set the speech resource key and region values?\`
          );
        }
        synthesizer.close();
        synthesizer = null;
        isLoading.value = false;
      },
      function (err) {
        isLoading.value = false;
        console.trace(\`Error: \${err}\`);
        synthesizer.close();
        synthesizer = null;
      }
    );
  }
};
</script>`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(126);
    console.log(data)
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
