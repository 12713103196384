<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">Part Series</div>
      <p>
        <a [routerLink]="partSeries[0].url">{{ partSeries[0].title }}</a>
      </p>
      <p>
        <a [routerLink]="partSeries[2].url">{{ partSeries[2].title }}</a>
      </p>
      <div class="post-step">Part 2 - ASP.NET Core Web API Setup</div>
      <div class="post-sub-step">1. Create your web API solution.</div>
      <p>
        1. Open the Visual Studio and choose the web api application. I'm using
        <code>net6.0</code> framework but you can use the older version of .net
        core.
      </p>
      <div class="post-sub-step">
        2. Install <code>Microsoft.Identity.Web</code>
      </div>
      <p>
        2.1 Go to the nuget package manager and search for
        <code>Microsoft.Identity.Web</code> and install.
      </p>
      <p>
        Alternatively, you can use the .NET CLI version which is the:
        <code>dotnet add package Microsoft.Identity.Web</code>
      </p>
      <div class="post-sub-step">
        3. Update the <code>appsettings.json</code> for the
        <code>AzureAD</code> key.
      </div>
      <p>Here's the sample reference.</p>
      <pre>
        <code [highlight]="codeAppSettings"></code>
      </pre>
      <p>
        As you can see, we also included our <code>scope</code> because we will
        need it in our controller.
      </p>
      <div class="post-sub-step">4. Configure <code>Startup.cs</code></div>
      <p>4.1 Add <code>Authentication</code> to the container</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        4.2 Register <code>CORS</code> policy for our client app inside the
        container.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-sub-step">
        5. Configure HTTP request pipeline for <code>CORS</code> and to use
        Authentication and Authorization.
      </div>
      <p>5.1 Add this below the <code>app.UseHttpsRedirection();</code></p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <div class="post-sub-step">6. Authenticate endpoints</div>
      <p>6.1 Add this above your controller name.</p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <p>6.2 Authorize the endpoints.</p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <p>
        In the example, we have an endpoint that allows the access only if the
        Azure AD user has an Admin role. We also have the
        <code>[Authorize]</code> without the role assigned, this means that
        regardless of the role, as long as the Azure AD user is part of the user
        inside the app registration for the web api, then the user will have an
        access.
      </p>
      <p>You can see the reference below.</p>
      <img
        src="../../../../../../assets/0322/7.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>Let's test using postman if the endpoints are already authorize.</p>
      <img
        src="../../../../../../assets/0322/8.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>As expected, we receive the 401 Unauthorized result.</p>
      <div class="post-sub-step">Summary</div>
      <p>
        On this blog, we worked on setting up our ASP.NET Core Web API solution,
        install necessary package and configure the app to properly authenticate
        and authorize the application. On the last part, we'll setup our
        frontend to communicate or connect with our backend solution.
      </p>
      <div class="level">
        <div class="level-left">
          <a class="button is-info" [routerLink]="partSeries[0].url"
            >PREVIOUS Page</a
          >
        </div>
        <div class="level-right">
          <a class="button is-info" [routerLink]="partSeries[2].url"
            >NEXT Page</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
