import { AppComponent } from './app.component';
import { AboutMeComponent } from './components/about-me/about-me.component';
import { BlogComponent } from './components/blog/blog.component';
import { AddAzureCognitiveServicesSpeechToTextToYourVuejsAppComponent } from './components/blog/post/pages/add-azure-cognitive-services-speech-to-text-to-your-vuejs-app/add-azure-cognitive-services-speech-to-text-to-your-vuejs-app.component';
import { AddAzureCognitiveServicesTextToSpeechToYourVuejsAppComponent } from './components/blog/post/pages/add-azure-cognitive-services-text-to-speech-to-your-vuejs-app/add-azure-cognitive-services-text-to-speech-to-your-vuejs-app.component';
import { AddImageAnalyzerToYourVuejsUsingAzureComputerVisionComponent } from './components/blog/post/pages/add-image-analyzer-to-your-vuejs-using-azure-computer-vision/add-image-analyzer-to-your-vuejs-using-azure-computer-vision.component';
import { AnalyzeImageInReactjsUsingAzureComputerVisionComponent } from './components/blog/post/pages/analyze-image-in-reactjs-using-azure-computer-vision/analyze-image-in-reactjs-using-azure-computer-vision.component';
import { CommonOptionsInPassingDataBetweenComponentsInAngularComponent } from './components/blog/post/pages/common-options-in-passing-data-between-components-in-angular/common-options-in-passing-data-between-components-in-angular.component';
import { CreateAndPublishYourFirstNpmPackageComponent } from './components/blog/post/pages/create-and-publish-your-first-npm-package/create-and-publish-your-first-npm-package.component';
import { CreateServerSidePaginationInAspnetCoreComponent } from './components/blog/post/pages/create-server-side-pagination-in-aspnet-core/create-server-side-pagination-in-aspnet-core.component';
import { CreateSimpleCustomUrlRedirectionForYourJavascriptAppComponent } from './components/blog/post/pages/create-simple-custom-url-redirection-for-your-javascript-app/create-simple-custom-url-redirection-for-your-javascript-app.component';
import { CreateYourFirstActionFilterForYourAspNetCoreApplicationComponent } from './components/blog/post/pages/create-your-first-action-filter-for-your-asp-net-core-application/create-your-first-action-filter-for-your-asp-net-core-application.component';
import { DataSeedingMadeEasierInAspnetCoreAppAndEfCoreComponent } from './components/blog/post/pages/data-seeding-made-easier-in-aspnet-core-app-and-ef-core/data-seeding-made-easier-in-aspnet-core-app-and-ef-core.component';
import { HostVuejsOnIisComponent } from './components/blog/post/pages/host-vuejs-on-iis/host-vuejs-on-iis.component';
import { HowToAddLiveChatFeatureInVuejsReactjsOrAngularComponent } from './components/blog/post/pages/how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular/how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular.component';
import { HowToDeployVueJsAppOnAzureUsingVscodeComponent } from './components/blog/post/pages/how-to-deploy-vue-js-app-on-azure-using-vscode/how-to-deploy-vue-js-app-on-azure-using-vscode.component';
import { HowToGetEnumDisplayNameInCsharpNetComponent } from './components/blog/post/pages/how-to-get-enum-display-name-in-csharp-net/how-to-get-enum-display-name-in-csharp-net.component';
import { HowToGetTheCurrentRunidUsingMlflowInAzureDatabricksComponent } from './components/blog/post/pages/how-to-get-the-current-runid-using-mlflow-in-azure-databricks/how-to-get-the-current-runid-using-mlflow-in-azure-databricks.component';
import { HowToSetupPostgresqlInAspNetCoreComponent } from './components/blog/post/pages/how-to-setup-postgresql-in-asp-net-core/how-to-setup-postgresql-in-asp-net-core.component';
import { HowToTrainYourDragonComponent } from './components/blog/post/pages/how-to-train-your-dragon/how-to-train-your-dragon.component';
import { IntegrateChatgptWithVuejsAppUsingOpenaiApiComponent } from './components/blog/post/pages/integrate-chatgpt-with-vuejs-app-using-openai-api/integrate-chatgpt-with-vuejs-app-using-openai-api.component';
import { IntegrateReduxtoolkitInDynamicReactjsTypescriptAppComponent } from './components/blog/post/pages/integrate-reduxtoolkit-in-dynamic-reactjs-typescript-app/integrate-reduxtoolkit-in-dynamic-reactjs-typescript-app.component';
import { IntegrateSpeechToTextAzureCognitiveServicesInReactjsComponent } from './components/blog/post/pages/integrate-speech-to-text-azure-cognitive-services-in-reactjs/integrate-speech-to-text-azure-cognitive-services-in-reactjs.component';
import { IntegrateTextToSpeechAzureCognitiveServicesInReactjsComponent } from './components/blog/post/pages/integrate-text-to-speech-azure-cognitive-services-in-reactjs/integrate-text-to-speech-azure-cognitive-services-in-reactjs.component';
import { LazyLoadAnImageAndDetectWhenItsLoadedInReactjsComponent } from './components/blog/post/pages/lazy-load-an-image-and-detect-when-its-loaded-in-reactjs/lazy-load-an-image-and-detect-when-its-loaded-in-reactjs.component';
import { LazyLoadAnImageAndDetectWhenItsLoadedInVuejsComponent } from './components/blog/post/pages/lazy-load-an-image-and-detect-when-its-loaded-in-vuejs/lazy-load-an-image-and-detect-when-its-loaded-in-vuejs.component';
import { LearnHowToBeABeeComponent } from './components/blog/post/pages/learn-how-to-be-a-bee/learn-how-to-be-a-bee.component';
import { ReactjsMsalWithAspnetcoreToUseAzureadPart1Component } from './components/blog/post/pages/reactjs-msal-with-aspnetcore-to-use-azuread-part1/reactjs-msal-with-aspnetcore-to-use-azuread-part1.component';
import { ReactjsMsalWithAspnetcoreToUseAzureadPart2Component } from './components/blog/post/pages/reactjs-msal-with-aspnetcore-to-use-azuread-part2/reactjs-msal-with-aspnetcore-to-use-azuread-part2.component';
import { ReactjsMsalWithAspnetcoreToUseAzureadPart3Component } from './components/blog/post/pages/reactjs-msal-with-aspnetcore-to-use-azuread-part3/reactjs-msal-with-aspnetcore-to-use-azuread-part3.component';
import { SeedUsersAndRolesInAspNetCoreWithEntityFrameworkCoreComponent } from './components/blog/post/pages/seed-users-and-roles-in-asp-net-core-with-entity-framework-core/seed-users-and-roles-in-asp-net-core-with-entity-framework-core.component';
import { SettingUpAutomapperInAspnetCoreComponent } from './components/blog/post/pages/setting-up-automapper-in-aspnet-core/setting-up-automapper-in-aspnet-core.component';
import { SetupSignalrInAspnetCoreApplicationComponent } from './components/blog/post/pages/setup-signalr-in-aspnet-core-application/setup-signalr-in-aspnet-core-application.component';
import { PostFooterComponent } from './components/blog/post/shared/post-footer/post-footer.component';
import { PostHeaderComponent } from './components/blog/post/shared/post-header/post-header.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactMeComponent } from './components/contact-me/contact-me.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { TilesComponent } from './components/homepage/tiles/tiles.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { NavbarComponent } from './shared/layout/navbar/navbar.component';
import { SocialMediaIconComponent } from './shared/social-media-icon/social-media-icon.component';

export const declarations = [
  AppComponent,
  NavbarComponent,
  ContactFormComponent,
  HomepageComponent,
  PrivacyPolicyComponent,
  ContactMeComponent,
  AboutMeComponent,
  TilesComponent,
  SocialMediaIconComponent,
  FooterComponent,
  PageNotFoundComponent,
  // blog related
  BlogComponent,
  PostHeaderComponent,
  PostFooterComponent,
  // posts
  HowToTrainYourDragonComponent,
  LearnHowToBeABeeComponent,
  CreateSimpleCustomUrlRedirectionForYourJavascriptAppComponent,
  HowToGetEnumDisplayNameInCsharpNetComponent,
  HowToDeployVueJsAppOnAzureUsingVscodeComponent,
  HowToGetTheCurrentRunidUsingMlflowInAzureDatabricksComponent,
  SeedUsersAndRolesInAspNetCoreWithEntityFrameworkCoreComponent,
  CreateServerSidePaginationInAspnetCoreComponent,
  HostVuejsOnIisComponent,
  HowToSetupPostgresqlInAspNetCoreComponent,
  SettingUpAutomapperInAspnetCoreComponent,
  HowToAddLiveChatFeatureInVuejsReactjsOrAngularComponent,
  DataSeedingMadeEasierInAspnetCoreAppAndEfCoreComponent,
  SetupSignalrInAspnetCoreApplicationComponent,
  CommonOptionsInPassingDataBetweenComponentsInAngularComponent,
  CreateYourFirstActionFilterForYourAspNetCoreApplicationComponent,
  CreateAndPublishYourFirstNpmPackageComponent,
  ReactjsMsalWithAspnetcoreToUseAzureadPart1Component,
  ReactjsMsalWithAspnetcoreToUseAzureadPart2Component,
  ReactjsMsalWithAspnetcoreToUseAzureadPart3Component,
  IntegrateReduxtoolkitInDynamicReactjsTypescriptAppComponent,
  IntegrateTextToSpeechAzureCognitiveServicesInReactjsComponent,
  IntegrateSpeechToTextAzureCognitiveServicesInReactjsComponent,
  AnalyzeImageInReactjsUsingAzureComputerVisionComponent,
  AddAzureCognitiveServicesTextToSpeechToYourVuejsAppComponent,
  AddAzureCognitiveServicesSpeechToTextToYourVuejsAppComponent,
  IntegrateChatgptWithVuejsAppUsingOpenaiApiComponent,
  AddImageAnalyzerToYourVuejsUsingAzureComputerVisionComponent,
  LazyLoadAnImageAndDetectWhenItsLoadedInVuejsComponent,
  LazyLoadAnImageAndDetectWhenItsLoadedInReactjsComponent
];
