import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-setup-signalr-in-aspnet-core-application',
  templateUrl: './setup-signalr-in-aspnet-core-application.component.html',
  styleUrls: ['./setup-signalr-in-aspnet-core-application.component.css']
})
export class SetupSignalrInAspnetCoreApplicationComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `using System.Threading.Tasks;
using Microsoft.AspNetCore.SignalR;
  
namespace MyApp.Hubs
{
  public class MainHub : Hub
  {
    private readonly IHubContext<MainHub> hubContext;
  
    public MainHub(IHubContext<MainHub> hubContext)
    {
      this.hubContext = hubContext;
    }
  
    public async Task SendNotification(string message)
    {
      await hubContext.Clients.All.SendAsync("receivesNotification", message);
    }
  }
}
`;

  code2 = `public void ConfigureServices(IServiceCollection services)
{

  services.AddControllers();
  services.AddSwaggerGen(c =>
  {
    c.SwaggerDoc("v1", new OpenApiInfo { Title = "MyApp", Version = "v1" });
  });
  
  // SIGNALR
  services.AddSignalR(); 
}`

  code3 = `app.UseEndpoints(endpoints =>
{
  endpoints.MapControllers();
  endpoints.MapHub<MainHub>("/hubs/main");
});`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(115);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
