<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <img src="../../../../../../assets/011621/01.jpg" class="img-responsive" />
  <div class="post-content-container">
    <div class="post-content-text">
      <p>
        We’re setting up a simple redirection for Vue.JS, React.JS and Angular.
        But you can also try to implement or make similar approach on Vanilla.Js
        and other JS frameworks or libraries.
      </p>
      <div class="post-step">1. Add path in your app framework routing</div>
      <p>
        Make sure to register this path on your routing system. Examples below.
      </p>
      <div class="post-sub-step">For React.JS</div>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <div class="post-sub-step">For Vue.JS</div>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <div class="post-sub-step">For Angular</div>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        <code>r</code> stands for redirection. I just made it simplified in our
        url path.
      </p>
      <p>
        Alternatively, you can store the previous page path in
        <code>localStorage</code> or <code>sessionStorage</code> depending on
        your requirements.
      </p>
      <div class="post-step">
        2. Add the redirection logic inside the method that checks or triggers
        the session timeout.
      </div>
      <p>
        I decided to put this simple javascript logic inside JWT token
        expiration validator. So when the JWT token is expired, then we redirect
        the user to the login page with that previous page attach to its url.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <p>
        If you’re using other session validator, then it’s fine. The main
        highlight of that code is our
      </p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <div class="post-step">3. Check if successfully login.</div>
      <p>
        In this step, let’s assume that we’re at the login page with attached
        URL of the previous page. For example,
      </p>
      <pre>
        <code [highlight]="code6"></code>
      </pre>
      <p>That’s it. I hope you find this blog helpful.</p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>
