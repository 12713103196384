import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-create-server-side-pagination-in-aspnet-core',
  templateUrl: './create-server-side-pagination-in-aspnet-core.component.html',
  styleUrls: ['./create-server-side-pagination-in-aspnet-core.component.css'],
})
export class CreateServerSidePaginationInAspnetCoreComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `namespace BusinessLogic.Helpers
{
  public class PaginatedData<T> : List<T>
  {
    public int PageIndex { get; private set; }
    public int TotalPages { get; private set; }
    public int TotalData { get; private set; }
  
    public PaginatedData(IEnumerable<T> items, int count, int pageIndex, int pageSize)
    {
      PageIndex = pageIndex;
      TotalPages = (int)Math.Ceiling(count / (double)pageSize);
      TotalData = count;
      this.AddRange(items);
    }
  
    public static PaginatedData<T> CreateList(IList<T> source, int pageIndex, int pageSize)
    {
      var count = source.Count();
  
      var items = source.Skip((pageIndex - 1) * pageSize).Take(pageSize).ToList();
      return new PaginatedData<T>(items, count, pageIndex, pageSize);
    }
  }
}`;

  code2 = `public async Task<PaginatedData<ProductDto>> PaginatedProducts(int pageIndex, int pageSize)
{
    var products = await context.Products().ToList();
    return PaginatedData<ProductDto>.CreateList(products, pageIndex, pageSize);
}`;

  code3 = `[HttpGet("pagination/{pageIndex}/{pageSize}")]
public async Task<IActionResult> PaginatedGetProduct(int pageIndex, int pageSize)
{
    if (pageIndex < 0 || pageSize < 0)
        return BadRequest();

    var data = await productService.PaginatedCustomers(pageIndex, pageSize);
    return Ok(new
    {
        data,
        data.PageIndex,
        data.TotalData,
        data.TotalPages
    });
}`;

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(112);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
