<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/73122/01vue.png"
        alt="Vue.js Text to Speech - dnilvincent.net"
        class="img-responsive"
      />
      <br />
      Related post:
      <a
        href="/blog/posts/integrate-azure-cognitive-services-text-to-speech-to-your-Vuejs-app"
        target="_blank"
      >
        <strong>
          Integrate Azure Cognitive Services Text to Speech to your Vue.JS App
        </strong>
      </a>
      <div class="post-step">Introduction</div>
      <p>
        <strong>Text-to-speech</strong> or <strong>TTS</strong> is a type of
        assistive technology that reads certain digital text aloud. This
        technology brings a lot of benefits in different industries, whether in
        teaching, announcement, broadcasting, entertainment and even to you when
        you're bored reading certain text. Why not just listen to them instead?
      </p>
      <p>
        This technology not only gives us efficiency in the majority, but it
        also beneficial to those people with literacy difficulties, learning
        disabilities, reduced vision and those learning a language.
      </p>
      <p>
        These are the top cloud providers that offer Text-to-speech API:
        <a
          href="https://azure.microsoft.com/en-us/services/cognitive-services/text-to-speech/"
          target="_blank"
          >Microsoft Azure Text to speech</a
        >,
        <a
          href="https://cloud.google.com/text-to-speech/?utm_source=google&utm_medium=cpc&utm_campaign=japac-PH-all-en-dr-bkws-all-pkws-trial-e-dr-1009882&utm_content=text-ad-none-none-DEV_c-CRE_602400515993-ADGP_Hybrid%20%7C%20BKWS%20-%20EXA%20%7C%20Txt%20~%20AI%20%26%20ML%20~%20Text-to-Speech_Global%20gap%20analysis-KWID_43700071566785764-aud-1596662389094%3Akwd-506032294992&userloc_20831-network_g&utm_term=KW_google%20cloud%20text%20to%20speech&gclid=Cj0KCQjw0JiXBhCFARIsAOSAKqCwxJbKF_LuHmJmwrJGm-j2QjKpy412Q62DyrrkKDJ5GD8g6O0t6QYaAnGaEALw_wcB&gclsrc=aw.ds"
          target="_blank"
          >Google Cloud Text-to-Speech</a
        >
        &
        <a
          href="https://aws.amazon.com/free/machine-learning/?trk=9bb5fb09-90d0-4dda-ab3a-51a73c6957d3&sc_channel=ps&sc_campaign=acquisition&sc_medium=ACQ-P|PS-GO|Brand|Desktop|SU|Machine%20Learning|Solution|PH|EN|Text&s_kwcid=AL!4422!3!596114562693!e!!g!!amazon%20text%20to%20speech&ef_id=Cj0KCQjw0JiXBhCFARIsAOSAKqBL9Vu4H6J1dwhtyr2gAqQ4lcKmqkxAsyLR2RoksWDXAP1q8a_E4ZkaAl7pEALw_wcB:G:s&s_kwcid=AL!4422!3!596114562693!e!!g!!amazon%20text%20to%20speech"
          target="_blank"
          >Amazon Text-to-Speech</a
        >. Other cloud services also offer this service, but it depends on you
        what will you choose.
      </p>
      <p>
        But in this blog, we'll be using
        <a
          href="https://azure.microsoft.com/en-us/services/cognitive-services/#api"
          target="_blank"
          >Microsoft Azure Cognitive Services</a
        >
        Text to speech. Without further ado, let's start coding!
      </p>
      <div class="post-step">FTF (First Things First)</div>
      <div class="post-sub-step">Prerequisites</div>
      <p>1. Machine with your text editor/IDE</p>
      <p>
        2. Microsoft Azure Account
        <a href="https://azure.microsoft.com/en-us/free/">(Try it for free)</a>
      </p>
      <p>3. Vue.JS Application</p>
      <div class="post-step">1. Create Cognitive Services in Azure Portal</div>
      <p>
        1.1 Create resource in Azure Portal. (Make sure you already have
        subscription whether free or paid in Azure)
      </p>
      <img
        src="../../../../../../assets/73122/02.png"
        alt="Vue.js Text to Speech - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        Below is a sample. Click the "Create" then once the creation is done,
        click the button "Go to resource"
      </p>
      <img
        src="../../../../../../assets/73122/03.png"
        alt="Vue.js Text to Speech - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.2 click the "Click here to manage keys" to navigate to the key
        section.
      </p>
      <img
        src="../../../../../../assets/73122/04.png"
        alt="Vue.js Text to Speech - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.3 Save the keys because we are going to need them on our Vue.js
        configuration.
      </p>
      <img
        src="../../../../../../assets/73122/05.png"
        alt="Vue.js Text to Speech - dnilvincent.net"
        class="img-responsive"
      />
      <div class="post-step">2. Install Package in Vue.JS App</div>
      <p>2.1 <code>npm i microsoft-cognitiveservices-speech-sdk</code></p>
      <a
        href="https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk"
        target="_blank"
        >https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk</a
      >
      <div class="post-step">3. Configure the Vue.JS App</div>
      <p>
        On my sample, I'm using the Vue.JS with Composition API project
        template. For this demo, I'm overwriting the <code>App.vue</code> file.
      </p>
      <p>3.1 Import the package below.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>3.2 Inside the script, configure the speech sdk</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        3.3 Create a function that invoke the <code>speakTextAsync</code> from
        the SDK's Synthesizer
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        3.4 Call the function from your UI and trigger the
        <code>textToSpeech()</code> function.
      </p>
      <p>
        Below is a sample code that you paste and play around on your machine.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-step">Additional Info</div>
      <p>
        You can change the <code>speechSynthesisVoiceName</code> or speech voice
        from this complete
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/language-support?tabs=speechtotext#text-to-speech"
          target="_blank"
          >list.</a
        >
      </p>
      <p>
        You can read more about this API from this
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/text-to-speech"
          target="_blank"
          >documentation.</a
        >
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
