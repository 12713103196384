import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-setting-up-automapper-in-aspnet-core',
  templateUrl: './setting-up-automapper-in-aspnet-core.component.html',
  styleUrls: ['./setting-up-automapper-in-aspnet-core.component.css']
})
export class SettingUpAutomapperInAspnetCoreComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `public class CustomerDto {
  public int CustomerId {get;set}
  public string Firstname {get;set}
  ... (let's say you have many properties)
}`

  code2 = `public class Customer {
  public int CustomerId {get;set}
  public string Firstname {get;set}
  ...
}`

  code3 = `public void ManuallyMap(CustomerDto dto){
  var customer = new Customer{
    CustomerId = dto.CustomerId,
    Firstname = dto.Firstname,
    ... so on and so forth.
  }
}`

  code4 = `using AutoMapper;

public class AutoMapperProfile : Profile 
{
    public AutoMapperProfile()
    {
        CreateMap<CustomerDto, Customer>();
    }
}`

  code5 = `public class Startup
{
    public Startup(IConfiguration configuration)
    {
        Configuration = configuration;
        Mapper.Initialize(cfg =>
        {
            cfg.AddProfile<AutoMapperProfile>();
        });
    }

    // This method gets called by the runtime. Use this method to add services to the container.
    public void ConfigureServices(IServiceCollection services)
    {
        ...
        // add Automapper
        services.AddAutoMapper();
        ...
    }
}`

  code6 = `public class CustomerService 
{
    private readonly IMapper mapper;
    public CustomerService(IMapper mapper)
    {
        this.mapper = mapper;
    }

    public Customer CustomerDtoToCustomer(CustomerDto customerDto)
    {
        return mapper.Map<Customer>(customerDto);
    }

    public CustomerDto CustomerToCustomerDto(Customer customer)
    {
        return mapper.Map<CustomerDto>(customer);
    }
}`

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(105);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
