<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/83122/1vuejs.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <div class="post-step">Introduction</div>
      <p>
        In my previous blog post with a title:
        <a
          href="https://blog.dnilvincent.net/blog/posts/integrate-azure-cognitive-services-speech-to-text-to-your-reactjs-app"
          target="_blank"
          >Integrate Azure Cognitive Services Speech to Text to your React.JS
          App</a
        >, I already wrote an introduction regarding the "Speech-to-text" and
        how it helps people nowadays. But in case you want to read the advantages of using speech-to-text, I've added them below for your reference.
      </p>
      <p>Here are the advantages of the Speech-to-Text:</p>
      <ul>
        <li>
          <strong>* Ease of Communication</strong> - No more or lessen the
          illegible of typing or writing.
        </li>
        <li><strong>* Increase Efficiency & Less Paperwork</strong></li>
        <li><strong>* Can Produce Faster Documents</strong></li>
        <li>
          <strong>* It Solves Inefficiencies and Reduces Wasted Time</strong>
        </li>
        <li>
          <strong
            >* Flexibility to Share Across Different Devices and more</strong
          >
        </li>
      </ul>
      <p>
        Truly, the technologies are evolving and innovations are keep on moving
        up. One of the modern improvements is the Cognitive Services. These
        services are available via subscriptions from the top companies like
        Microsoft, Google and Amazon.
      </p>
      <p>
        For this blog, we'll be using Azure Cognitive Services to integrate the
        Speech to Text to our Vue.JS.
      </p>
      You can visit the official documentation from
      <a
        href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/index-speech-to-text"
        target="_blank"
        >Microsoft Docs Speech-to-Text Cognitive Services</a
      >
      to learn more.
      <div class="post-step">FTF (First Things First)</div>
      <div class="post-sub-step">Prerequisites</div>
      <p>1. Machine with your text editor/IDE</p>
      <p>
        2. Microsoft Azure Account
        <a href="https://azure.microsoft.com/en-us/free/">(Try it for free)</a>
      </p>
      <p>3. Vue.JS Application</p>
      <div class="post-step">1. Create Cognitive Services in Azure Portal</div>
      <p>
        1.1 Create resource in Azure Portal. (Make sure you already have
        subscription whether free or paid in Azure)
      </p>
      <img
        src="../../../../../../assets/73122/02.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        Below is a sample. Click the "Create" then once the creation is done,
        click the button "Go to resource"
      </p>
      <img
        src="../../../../../../assets/73122/03.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.2 click the "Click here to manage keys" to navigate to the key
        section.
      </p>
      <img
        src="../../../../../../assets/73122/04.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.3 Save the keys because we are going to need them on our vue.js
        configuration.
      </p>
      <img
        src="../../../../../../assets/73122/05.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <div class="post-step">2. Install Package in Vue.JS App</div>
      <p>2.1 <code>npm i microsoft-cognitiveservices-speech-sdk</code></p>
      <a
        href="https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk"
        target="_blank"
        >https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk</a
      >
      <div class="post-step">3. Configure the Vue.JS App</div>
      <p>
        On my sample, I'm using the Vue.JS project template. For this demo, I'm
        overwriting the <code>App.tsx</code> file.
      </p>
      <p>3.1 Import the package below.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>3.2 Inside your component function, configure the speech sdk</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        3.3 Create a function that invoke the <code>recognizeOnceAsync</code> from
        the SDK's Synthesizer
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        3.4 Call the function inside the <code>useEffect</code> or create an UI
        that has a button and input that trigger the function.
      </p>
      <p>
        Below is a sample code that you paste and play around on your machine.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-step">Additional Info</div>
      <p>
        You can read the official documentation from Microsoft where you can learn
        more about real-time speech-to-text, batch speech-to-text and the custom
        speech.
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/index-speech-to-text"
          target="_blank"
          >Speech-to-text documentation
        </a>
      </p>
      <p>
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/faq-stt"
          target="_blank"
          >Speech-to-text FAQs</a
        >
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
