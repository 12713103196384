<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">Part Series</div>
      <p>
        <a [routerLink]="partSeries[0].url">{{ partSeries[0].title }}</a>
      </p>
      <p>
        <a [routerLink]="partSeries[1].url">{{ partSeries[1].title }}</a>
      </p>
      <div class="post-step">
        Part 3 - Setup React.js to Properly Connect to the Backend Endpoints
      </div>
      <div class="post-sub-step">
        1. Configure App Registration in Azure AD.
      </div>
      <p>
        1.1 Follow the
        <a href="partSeries[0].url" target="_blank">Part 1</a> step
        <strong>1</strong> to create the app registration. But for this, we
        create the app registration for our react.js application.
      </p>
      <img
        src="../../../../../../assets/0322/9.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <p>
        <i
          >The supported account is still depends on what type that you
          prefer.</i
        >
      </p>
      <div class="post-sub-step">
        2. Select the tokens you would like to be issued by the authorization
        endpoint
      </div>
      <p>
        2.1 Open the app registration for frontend, go to
        <strong>Authentication</strong> and check the
        <strong>Access Token</strong> and <strong>ID Tokens</strong> (ID Tokens
        in case for implicit and hybrid flows). Then hit <strong>Save</strong>
      </p>
      <img
        src="../../../../../../assets/0322/10.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">
        3. Add user via Enterprise Applications Page
      </div>
      <p>
        3.1 Go back to your Azure AD, click the
        <strong>Enterprise applications</strong> and choose your application.
        Once it's open, then choose the <strong>Users and groups</strong>
      </p>
      <p>
        3.2 Add user/group then select the users that you want to have the
        access to our frontend application.
      </p>
      <img
        src="../../../../../../assets/0322/12.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">
        4. Go your React.js app and run this command to install the following
        packages.
      </div>
      <code> npm i @azure/msal-browser @azure/msal-react axios bulma</code>
      <p><i>axios & bulma are only optional packages.</i></p>
      <div class="post-sub-step">5. Create <code>authConfig</code> file</div>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>You must need to update these.</p>
      <li><code>clientId</code></li>
      <li><code>authority</code></li>
      <li><code>loginApiRequest</code></li>
      <div class="post-sub-step">
        6. Update the <code>index.ts</code> or <code>index.js</code>
      </div>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        As you can see above we wrapped the <code>App</code> component in the
        <code>MsalProvider</code>. We also created the axios interceptor so when
        we connect to our endpoints, the <code>accessToken</code> will be
        attached to the request. You can also notice that in order to acquire
        the access token, we use the
        <code>msalInstance.getAllAccounts()[0]</code> in order to get the
        account who is signed in.
      </p>
      <p>
        To learn more about MSAL.js, visit this
        <a
          href="https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications"
          target="_blank"
          >link here.</a
        >
      </p>
      <div class="post-sub-step">6. Create Sign and Signout components</div>
      <p>6.1 For <code>SignIn.tsx</code> here's our code:</p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>6.1 For <code>Signout.tsx</code> here's our code:</p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-sub-step">
        7. Create Employee component that will connect to our Web API endpoints
      </div>
      <p>7.1 Paste the following codes below for reference.</p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <div class="post-sub-step">8. Update <code>App.tsx</code></div>
      <p>Here, we put everything inside our <code>App.tsx</code>.</p>
      <pre>
        <code [highlight]="code7"></code>
      </pre>
      <p>
        As you can see above, we add the <code>AuthenticatedTemplate</code> as
        well as the <code>UnauthenticatedTemplate</code>. We also import our
        Sign In and Sign Out buttons and the Employee component.
      </p>
      <div class="post-sub-step">
        9. Verify the <code>api.scope</code> if it's properly inherited in the
        app registration of our React.js application.
      </div>
      <p>
        9.1 Go to the Azure AD app registration of the react.js and choose the
        <strong>API permissions</strong> on the left menu. Notice the
        <strong>Other permissions granted for Default Directory</strong> the web
        api is already listed there.
      </p>
      <img
        src="../../../../../../assets/0322/14.png"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">
        10. Run and test the functionality of the application.
      </div>
      <p>
        Here, I prepare a gif file that shows the demo of the application. As
        you can remember we set role for a specific user which is for
        <strong>Samp White</strong>. <strong>Samp White</strong> has the admin
        access so this user can either view the employee records or check the
        total # of employees. While the user <strong>Mark Vincent</strong> has
        only the access to view the total employee.
      </p>
      <img
        src="../../../../../../assets/0322/demo.gif"
        [title]="postHeader.title"
        class="img-responsive"
      />
      <div class="post-sub-step">Summary</div>
      <p>
        On this final part of the series, we work on setting up the Azure AD for
        our frontend application, configure our React.js to use our app
        registrations and connect to our ASP.NET Core web api. As the result,
        we're able to authenticate and authorize certain users using Azure AD to
        access the endpoints in our web api.
      </p>
      <div class="level">
        <div class="level-left">
          <a class="button is-info" [routerLink]="partSeries[1].url"
            >PREVIOUS Page</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
