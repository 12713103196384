import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-integrate-chatgpt-with-vuejs-app-using-openai-api',
  templateUrl: './integrate-chatgpt-with-vuejs-app-using-openai-api.component.html',
  styleUrls: ['./integrate-chatgpt-with-vuejs-app-using-openai-api.component.scss']
})
export class IntegrateChatgptWithVuejsAppUsingOpenaiApiComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `import { Configuration, OpenAIApi } from "openai";`

  code2 = `// Create configuration
const config = new Configuration({
  apiKey: "YOUR_API_KEY",
});

// Add the config to OpenAIAPi instance
const openAi = new OpenAIApi(config);`

  code3 = `const question = ref<string>("")
const answer = ref<string>("");

const askQuestion = async () => {
  if (question.value) {
    const prompt = \`\${question.value} and return a response in the following parsable JSON format: {
      "answer": "answer"
    }\`;

    try {
      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompt,
        max_tokens: 2048
      });

      answer.value = JSON.parse(response.data.choices[0].text).answer;
    } catch (err) {
      console.error(err);
    }
  }
};`

code4 = `<template>
  <div>
    <input v-model="question" />
    <button @click="askQuestion()">Ask ChatGPT a question</button>
    <p v-show="answer">{{ answer }}</p>
  </div>
</template>`

code5 = `<script setup lang="ts">
import { ref } from "vue";
import { Configuration, OpenAIApi } from "openai";

// Setup OpenAI
const config = new Configuration({
  apiKey: "YOUR_API_KEY",
});
const openai = new OpenAIApi(config);

const question = ref<string>("");
const answer = ref<string>("");

const askQuestion = async () => {
  if (question.value) {
    const prompt = \`\${question.value} and return a response in the following parsable JSON format: {
      "answer": "answer"
    }\`;

    try {
      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompt,
        max_tokens: 2048
      });

      answer.value = JSON.parse(response.data.choices[0].text).answer;
    } catch (err) {
      console.error(err);
    }
  }
};
</script>

<template>
  <div>
    <input v-model="question" />
    <button @click="askQuestion()">Ask ChatGPT a question</button>
    <p v-show="answer">{{ answer }}</p>
  </div>
</template>`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(128);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
