<span class="footer-social">
  <a href="https://github.com/deanilvincent" target="_blank">
    <i class="fab fa-github"></i>
  </a>
</span>
<span class="footer-social">
  <a
    href="https://www.linkedin.com/in/mark-deanil-vicente-40676298"
    target="_blank"
  >
    <i class="fab fa-linkedin"></i>
  </a>
</span>
<span class="footer-social">
  <a href="https://twitter.com/DeanilVincent03" target="_blank">
    <i class="fab fa-twitter"></i>
  </a>
</span>
