<nav
  class="navbar is-fixed-top"
  style="background-color: white; border-bottom: 1px solid #e6e9ed"
>
  <div class="navbar-brand">
    <a class="navbar-item" href="https://dnilvincent.net">
      <img src="../../../../assets/header-logo.png" alt="dnilvincent.net" />
    </a>

    <a
      role="button"
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div class="navbar-menu">
    <div
      class="navbar-end"
      onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');"
    >
      <div class="navbar-item">
        <a
          class="navbar-item"
          href="https://dnilvincent.net"
          style="background-color: white"
        >
          Home
        </a>
        <a
          class="navbar-item"
          [routerLink]="['/blog']"
          style="background-color: white"
        >
          Blog
        </a>
        <a
          class="navbar-item"
          style="background-color: white"
          (click)="isOpenContactModal = true"
        >
          Contact Me
        </a>
        <a
          class="navbar-item"
          [routerLink]="['/privacy-policy']"
          style="background-color: white"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
</nav>
<!-- Contact Modal-->
<div class="modal is-active" *ngIf="isOpenContactModal">
  <div class="modal-background" (click)="isOpenContactModal = false"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Contact Me</p>
      <button
        class="delete"
        aria-label="close"
        (click)="isOpenContactModal = false"
      ></button>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">My Email</label>
        <div class="control has-icons-left has-icons-right">
          <input
            class="input"
            type="email"
            placeholder="Opps, why you removed the content? hehe"
            value="markdeanilvicente@gmail.com"
            readonly
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
      </div>
      <label class="label">Or reach me out here:</label>
      <app-social-media-icon></app-social-media-icon>
    </section>
    <footer class="modal-card-foot" style="justify-content: flex-end">
      <a
        class="button is-success"
        href="mailto:markdeanilvicente@gmail.com?subject = Hello&body = World"
        >Compose Email</a
      >
      <button class="button" (click)="isOpenContactModal = false">Close</button>
    </footer>
  </div>
</div>
