import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-data-seeding-made-easier-in-aspnet-core-app-and-ef-core',
  templateUrl: './data-seeding-made-easier-in-aspnet-core-app-and-ef-core.component.html',
  styleUrls: ['./data-seeding-made-easier-in-aspnet-core-app-and-ef-core.component.css']
})
export class DataSeedingMadeEasierInAspnetCoreAppAndEfCoreComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `namespace App.Models
{
  public class Person
  {
    [Key]
    public int PersonId { get; set; }
    public string Firstname { get; set; }
    public string Lastname { get; set; }
  }
}`

  code2 = `public class MyDbContext : DbContext
{
  public MyDbContext (DbContextOptions<MyDbContext> options) : base(options) {}

  protected override void OnModelCreating(ModelBuilder builder)
  {
    base.OnModelCreating(builder);

    builder.Entity<Person>()
      .HasData(new List<Person>
      {
        new Person {PersonId = 1, Firstname = "John", Lastname = "Doe"},
        new Person {PersonId = 2, Firstname = "Homer", Lastname = "Simpson"},
      });
  }

  public DbSet<Person> Persons { get; set; }
}`

code3 = `public static class MigrationUtil
{
    public static IHost MigrateDb(this IHost host)
    {
        using var scope = host.Services.CreateScope();
        using var context = scope.ServiceProvider.GetRequiredService<AppDbContext>();
        try
        {
            context.Database.Migrate();
        }
        catch (Exception e)
        {
            Debug.WriteLine(e);
            throw;
        }

        return host;
    }
}`

code4 = `public class Program
{
    public static void Main(string[] args)
    {
        CreateHostBuilder(args).Build().MigrateDb().Run();
    }

    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.UseStartup<Startup>();
            });
}`

code5 = `public static ModelBuilder SeedEntitites(this ModelBuilder builder)
{
    // Seed person
    builder.Entity<Person>().HasData(new List<Person>
    {
        new Person {PersonId = 1, Firstname = "Test", Lastname = "Test"},
        new Person {PersonId = 2, Firstname = "Test2", Lastname = "Test2"}
    });

    // Seed other more...

    return builder;
}`

code6 = `protected override void OnModelCreating(ModelBuilder builder)
{
    base.OnModelCreating(builder);

    builder.SeedEntitites();
}`

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(114);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
