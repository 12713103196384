import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-integrate-text-to-speech-azure-cognitive-services-in-reactjs',
  templateUrl: './integrate-text-to-speech-azure-cognitive-services-in-reactjs.component.html',
  styleUrls: ['./integrate-text-to-speech-azure-cognitive-services-in-reactjs.component.scss']
})
export class IntegrateTextToSpeechAzureCognitiveServicesInReactjsComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `const sdk = require("microsoft-cognitiveservices-speech-sdk");`

  code2 = `const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
// The language of the voice that speaks.
speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";

// Create the speech synthesizer.
let synthesizer = new sdk.SpeechSynthesizer(speechConfig);`

  code3 = `const test = () => {
synthesizer.speakTextAsync(
  "Enter your text here.",
    function (result: any) {
      if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
        console.log("synthesis finished.");
      } else {
        console.error(\`Speech synthesis canceled: \${result.errorDetails} \\nDid you set the speech resource key and region values?\`);
      }
      synthesizer.close();
      synthesizer = null;
      },
    function (err: any) {
      console.trace(\`Error: \${err}\`);
      synthesizer.close();
      synthesizer = null;
    }
  );
};`

  code4 = `import { useState } from "react";
const sdk = require("microsoft-cognitiveservices-speech-sdk");
  
function App() {
  const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
  const region = "westus2";
  const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
  // The language of the voice that speaks.
  speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
  
  // Create the speech synthesizer.
  let synthesizer = new sdk.SpeechSynthesizer(speechConfig);
  
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  
  const test = () => {
    setLoading(true);
    synthesizer.speakTextAsync(
      text,
      function (result: any) {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("synthesis finished.");
        } else {
          console.error(\`Speech synthesis canceled: \${result.errorDetails} \\nDid you set the speech resource key and region values?\`);
        }
        synthesizer.close();
        synthesizer = null;
        setLoading(false);
      },
      function (err: any) {
        console.trace(\`Error: \${err}\`);
        synthesizer.close();
        synthesizer = null;
      }
    );
  };
  
  return (
    <div className="App">
      <input onChange={(e) => setText(e.target.value)} />
      {loading ? "Loading..." : <button onClick={test}>Run Text to Speech</button>}
    </div>
  );
}
  
  export default App;
  `

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(123);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
