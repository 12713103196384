<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/83122/1.jpeg"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <div class="post-step">Introduction</div>
      <p>
        <strong>Speech-to-text</strong> or also known as
        <strong>Speech recognition</strong> enables real-time and batch
        transcription of audio or voice into text. Depending on the reference
        text input, speech to text also enables real-time pronunciation
        assessment and gives speakers feedback on the accuracy and fluency of
        spoken audio or voice.
      </p>
      <p>Below are the known advantages of this feature:</p>
      <ul>
        <li>
          <strong>* Ease of Communication</strong> - No more or lessen the
          illegible of typing or writing.
        </li>
        <li><strong>* Increase Efficiency & Less Paperwork</strong></li>
        <li><strong>* Can Produce Faster Documents</strong></li>
        <li>
          <strong>* It Solves Inefficiencies and Reduces Wasted Time</strong>
        </li>
        <li>
          <strong
            >* Flexibility to Share Across Different Devices and more</strong
          >
        </li>
      </ul>
      <p>
        There are also some common disadvantages like there could be background
        noise interference, not all voice recognition software won't always put
        the words on the screen completely accurately that's why it's important
        to see the generated text and fix them.
      </p>
      <p>
        The good thing about the Azure Cognitive Services Speech to Text, it's
        way smarter to lessen those common issues in voice recognition
      </p>
      To learn more, you can visit the official documentation from
      <a
        href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/index-speech-to-text"
        target="_blank"
        >Microsoft Docs Speech-to-Text Cognitive Services</a
      >
      <p>
        On this blog, we'll integrate the Speech-to-Text to our React.JS
        Application.
      </p>
      <div class="post-step">FTF (First Things First)</div>
      <div class="post-sub-step">Prerequisites</div>
      <p>1. Machine with your text editor/IDE</p>
      <p>
        2. Microsoft Azure Account
        <a href="https://azure.microsoft.com/en-us/free/">(Try it for free)</a>
      </p>
      <p>3. React.JS Application</p>
      <div class="post-step">1. Create Cognitive Services in Azure Portal</div>
      <p>
        1.1 Create resource in Azure Portal. (Make sure you already have
        subscription whether free or paid in Azure)
      </p>
      <img
        src="../../../../../../assets/73122/02.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        Below is a sample. Click the "Create" then once the creation is done,
        click the button "Go to resource"
      </p>
      <img
        src="../../../../../../assets/73122/03.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.2 click the "Click here to manage keys" to navigate to the key
        section.
      </p>
      <img
        src="../../../../../../assets/73122/04.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <p>
        1.3 Save the keys because we are going to need them on our react.js
        configuration.
      </p>
      <img
        src="../../../../../../assets/73122/05.png"
        alt="Mark Deanil Vicente - dnilvincent.net"
        class="img-responsive"
      />
      <div class="post-step">2. Install Package in React.JS App</div>
      <p>2.1 <code>npm i microsoft-cognitiveservices-speech-sdk</code></p>
      <a
        href="https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk"
        target="_blank"
        >https://www.npmjs.com/package/microsoft-cognitiveservices-speech-sdk</a
      >
      <div class="post-step">3. Configure the React.JS App</div>
      <p>
        On my sample, I'm using the React.JS project template. For this demo,
        I'm overwriting the <code>App.tsx</code> file.
      </p>
      <p>3.1 Import the package below.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>3.2 Inside your component function, configure the speech sdk</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        3.3 Create a function that invoke the <code>recognizeOnceAsync</code> from
        the SDK's Synthesizer
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        3.4 Call the function inside the <code>useEffect</code> or create an UI
        that has a button and input that trigger the function.
      </p>
      <p>
        Below is a sample code that you paste and play around on your machine.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-step">Additional Info</div>
      <p>
        Here's the official documentation from Microsoft where you can learn
        more about real-time speech-to-text, batch speech-to-text and the custom
        speech.
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/index-speech-to-text"
          target="_blank"
          >Speech-to-text documentation
        </a>
      </p>
      <p>
        <a
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/faq-stt"
          target="_blank"
          >Speech-to-text FAQ</a
        >
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
