import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-how-to-get-the-current-runid-using-mlflow-in-azure-databricks',
  templateUrl:
    './how-to-get-the-current-runid-using-mlflow-in-azure-databricks.component.html',
  styleUrls: [
    './how-to-get-the-current-runid-using-mlflow-in-azure-databricks.component.css',
  ],
})
export class HowToGetTheCurrentRunidUsingMlflowInAzureDatabricksComponent
  implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `dbutils.library.installPyPI("mlflow")
dbutils.library.restartPython()`;

  code2 = `# Import mlflow
import mlflow
import mlflow.sklearn
  
# import for unique folder naming
import uuid`;

  code3 = `# create unique folder number
unique_folder = str(uuid.uuid1())
  
def train_and_log_model(data):
  # Split dataset into training set and test set
  x_train, x_test, y_train, y_test = train_test_split(data.data, data.target, test_size=0.3,random_state=109)
  
  # Start an MLflow run; the "with" keyword ensures we'll close the run even if this cell crashes
  with mlflow.start_run():
    model = GaussianNB()
  
    # train the model
    model.fit(x_train,y_train)
  
    # Predict
    preds = model.predict(x_test)
          
    mlflow.sklearn.log_model(model, "model")
    modelpath = "/dbfs/mlflow/"+unique_folder+"/model"
    mlflow.sklearn.save_model(model, modelpath)
          
    client = mlflow.tracking.MlflowClient()
    active_run = client.get_run(mlflow.active_run().info.run_id).data
    log_model_history_json = json.loads(active_run.tags['mlflow.log-model.history'])
    log_model_history_data = log_model_history_json[0]
    return log_model_history_data['run_id']`;

  code4 = `run_id = train_and_log_model(data) # call to run
print(run_id)
# result 1ccc2a132124468e91e4ea636b6f4023`;

  constructor(
    private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.getBlogPostHeaderData();
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(108);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
