<div class="main-page-content-top-adjust"></div>
<div class="container container-side-adjust">
  <div class="box is-light">
    <h3 class="title is-3">Privacy Policy</h3>
    <hr />
    <p>
      We respect the privacy of all visitors who coming in on this website.
      We're using Google analytics (is a freemium web analytics service offered
      by Google that tracks and reports website traffic). It also collects
      anonymous data about the visitor of the website that integrates Google
      Analytics.
    </p>
    <hr />
    <h4 class="title is-4" style="color: gray">
      Some of the records that Google Analytic does
    </h4>
    <ul>
      <li>- What device visitors used to come here.</li>
      <li>- What kind of browser visitors used to come here.</li>
      <li>- What website visitors used to visit this site.</li>
      <li>- How long visitors stay on this website.</li>
      <li>- And much more.</li>
    </ul>
    <hr />
    <h4 class="title is-4" style="color: gray">
      What do we do with your data?
    </h4>
    <p>
      The tracking information allows us to better understand the kind of
      visitors who come to our site and what content they are reading.
      Occasionally, we compile aggregate statistics about the number of visitors
      this site receives and browsers being used. No personally identifying data
      is included in this type of reporting.
    </p>
    <br />
    <p>
      Since this site integrates Google Analytics, all of our activity falls
      within the boundaries of the
      <a
        href="https://www.google.com/analytics/terms/us.html"
        style="color: skyblue"
        ><u>Google Analytics Terms of Service</u></a
      >
    </p>
    <br />
    <h4 class="title is-4" style="color: gray">Disqus Privacy Policy</h4>
    <p>
      This site also uses Disqus. You can read about its
      <a
        href="https://help.disqus.com/en/articles/1717103-disqus-privacy-policy"
        target="_blank"
        >Privacy Policy</a
      >
      to learn more.
    </p>
    <p>
      If you have any questions with regard to this privacy statement, please
      tap the "Contact Me" on the menu bar or send me a chat here. Have a good one!
    </p>
  </div>
</div>
