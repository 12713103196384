<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/103122/1.jpg"
        [alt]="altImage"
        class="img-responsive"
      />
      <div class="post-step">Introduction</div>
      <p>
        Whether on social media, an
        ecommerce marketplace, news, or any application that displays an image,
        images play a critical role in how we engage with users. From the user’s
        perspective, they generally only care about viewing the image. However,
        from the development side, it’s very important how we display the
        images.
      </p>
      <p>
        There are many factors that need to be considered when working with
        images on our application. What kind of compressor should we use to
        reduce the image size? How long is the loading time of each image? Do we
        display the image from server or from a CDN? What dimensions should
        we choose? These are some common questions that we can think of when
        dealing with images. If the way of loading the images is unappealing, it
        might have an impact on the users who visit your website.
      </p>
      <p>
        In today's blog, we're going to implement a basic lazy loading that's
        already part of the HTML and detect if the image is loaded completely
        using Vue.JS
      </p>
      <div class="post-step">1. Use lazy loading of image attribute</div>
      <p>
        All you need is to add <code>`loading="lazy"`</code> on your image tag.
      </p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        What the <code>loading</code> attribute does is, it gives control over
        when the browser should start loading the resource. The
        <code>lazy</code> value is basically when the viewport reach the
        element, then it will start fetching the image from the source.
      </p>
      <p>Here's the information about the browser supports of this attribute</p>
      <img src="../../../../../../assets/103122/2.png" />
      <p>
        source:
        <a
          href="https://caniuse.com/?search=loading%20on%20image"
          target="_blank"
          >https://caniuse.com</a
        >
      </p>
      <div class="post-step">
        2. Detect if the image is already loaded using Vue.JS
      </div>
      <p>
        Basically, you just need to add <code>@load=""</code> on your image tag.
        That's it!
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      Here's the basic example where we add conditions that we only want to
      display an image once it's loaded.
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        You might consider loading images less than 1mb, if it's possible, so
        that you can have good user experience. Also, if you're using CDN, check
        if they support caching of your content.
      </p>
      <p>Well, That's it! I hope you enjoy this blog. Have a wonderful day!</p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
