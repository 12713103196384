<p>If you have some questions or comments, please drop it below 👇 :)</p>
<div class="footer-section-style">
  <div class="container">
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column is-2">
            <div class="is-center-text">
              <img
                src="../../../../../../assets/personal-img.jfif"
                class="circular--square"
                height="175"
                width="175"
              />
            </div>
          </div>
          <div class="column is-10">
            <h6 class="title is-6">About the author</h6>
            <p>
              Mark Deanil Vicente is passionate in sharing to the community by
              means of writing article & speaking about development technologies
              especially for web, cloud and mobile. He's also an active
              Microsoft Most Value Professional (MVP).
              <a href="https://dnilvincent.net">Read more...</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<a href="https://www.buymeacoffee.com/dnilvincent" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/default-orange.png" alt="Buy Me A Tea" height="41" width="174"></a>
