<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <img
      src="../../../../../../assets/113021/01.jpg"
      title="Action Filter in ASP.NET Core"
      class="img-responsive"
    />
    <div class="post-content-text">
      <p>
        <strong>Filters</strong> in ASP.NET Core offer an advantage if you use
        it properly. As a matter of fact, there's built-in filters that handle
        tasks such as: Authorization and Response caching. Custom filters can
        also be created to handle cross-cutting concerns like error handling,
        caching, configuration, authorization, and logging.
      </p>
      <p>
        Not all filter executes at the same in the filter pipeline but each
        filter type is executed at a different stage. As you can see at the
        image below: <strong>Authorization filters</strong> run first followed
        by <strong>Resource filters</strong> and so on and so forth.
      </p>
      <img
        src="../../../../../../assets/113021/02.png"
        title="Action Filter in ASP.NET Core"
        class="img-responsive"
      />
      <i
        >To learn more, visit this link from official Microsoft Docs:
        <a
          href="https://docs.microsoft.com/en-us/aspnet/core/mvc/controllers/filters?view=aspnetcore-6.0"
          target="_blank"
          >Filters in ASP.NET Core
        </a></i
      >
      <p>
        In this post, I'll guide you step by step on how to create basic action
        filter that checks if the id is already exist inside our record.
      </p>
      <div class="post-step">Steps in Creating Simple Custom Action Filter</div>
      <div class="post-sub-step">
        Step 1: Create your method and inherit the
        <code>IAsyncActionFilter</code>
      </div>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        In the code example above, we are using the
        <code>IAsyncActionFilter</code> but if you're working with synchronous,
        you can use the <code>IActionFilter</code> interface.
      </p>
      <p>
        <code>IAsyncActionFilter</code> has only one require method which is the
        <code>OnActionExecutionAsync</code> compare to
        <code>IActionFilter</code> which has 2 contracts that need to be
        implemented. These are the <code>OnActionExecuted</code>: a method
        before action executes and the <code>OnActionExecuted</code>: after the
        action executes.
      </p>
      <div class="post-sub-step">
        Step 2: Register inside the ConfigureServices in Startup.cs
      </div>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <br />
      <div class="post-sub-step">Step 3: Use it in your Controller Method</div>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        Depending on your requirement: You can also create the other filters and
        use it above your controller so that filter can be automatically applied
        to its methods.
      </p>
      <p>I hope this post helps you. Have a good day!</p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
