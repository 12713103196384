import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-lazy-load-an-image-and-detect-when-its-loaded-in-reactjs',
  templateUrl: './lazy-load-an-image-and-detect-when-its-loaded-in-reactjs.component.html',
  styleUrls: ['./lazy-load-an-image-and-detect-when-its-loaded-in-reactjs.component.scss']
})
export class LazyLoadAnImageAndDetectWhenItsLoadedInReactjsComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  altImage = "Analyze Image in Vue.JS using Azure Computer Vision - dnilvincent.net"

  code1 = `<img src="/.." loading="lazy">`

  code2 = `<img src='fromSomewhere' loading="lazy" onLoad={loadImage} />`

  code3 = `const [isImageLoaded, setIsImageLoaded] = useState(false);

const loadImage = () => {
  setIsImageLoaded(true);
};

return (
  <div className="App">
    {isImageLoaded ? null : <div>Loading...</div>}
    <img
      onLoad={loadImage}
      src="fromSomewhere"
    />
  </div>
);`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(131);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
