<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <p>
        “AutoMapper is a simple little library built to solve a deceptively
        complex problem - getting rid of code that mapped one object to another.
        This type of code is rather dreary and boring to write, so why not
        invent a tool to do it for us?” -
        <a href="https://automapper.org/" target="_blank">Automapper.org</a>
      </p>
      <p>
        Pretty sure you’ve seen or even tried using this kind of code scenario.
      </p>
      <p><code>CustomerDto.cs</code></p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>And you want to map it to your model class.</p>
      <p><code>Customer.cs </code></p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>What you usually do is manually do this:</p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <div class="post-step">FTF (First Things First)</div>
      <p>
        Make sure you’ve already created or you have existing ASP.NET Core
        project (MVC or Web API).
      </p>
      <div class="post-step">1.0 Install the Package</div>
      <p>
        1.1 Open the terminal and execute the command or open Nuget Package
        Manager. Look for this package.
        <code>AutoMapper.Extensions.Microsoft.DependencyInjection</code>
      </p>
      <img
        src="../../../../../../assets/092019/01.png"
        class="img-responsive"
        alt="AutoMapper in ASP.NET Core| Mark Deanil Vicente"
      />
      <code
        >Install-Package AutoMapper.Extensions.Microsoft.DependencyInjection
      </code>
      <p>This is an AutoMapper extensions for ASP.NET Core project.</p>
      <div class="post-step">2.0 Setup AutoMapper Helper class.</div>
      <p>
        I named this class <code>AutoMapperProfile.cs</code>. You can map
        multiple dtos & model class in there.
      </p>
      <p>
        I include this class inside my “Helpers” folder but it depends on you
        where do you want to store this class file.
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <div class="post-step">3.0 Modify Startup.cs.</div>
      <p>
        Add the automapper in the constructor & register the AutoMapper service
        in the <code>ConfigureServices</code> method.
      </p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <div class="post-step">4.0 Sample Usage.</div>
      <p>Inject first the <code>IMapper</code> in the class constructor.</p>
      <pre>
        <code [highlight]="code6"></code>
      </pre>
      <p>
        As you see from above, it will automatically map based on their
        property. It’s easy so try it on your future or existing projects.
      </p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>
