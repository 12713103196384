<div class="container container-side-adjust">
  <h4 class="title is-4 is-center-text">About Me</h4>
  <div class="columns">
    <div class="column is-2">
      <div class="is-center-text">
        <img
          src="../../../assets/personal-img.jfif"
          class="circular--square"
          height="175"
          width="175"
        />
      </div>
    </div>
    <div class="column is-10">
      <p>
        Hello again, and thanks for visiting my website. To introduce
        myself, I'm currently working as an app developer. I have a passion when
        it comes to technologies and innovations. I'm also active in sharing my
        knowledge and skills as a community technical resource speaker in some
        universities and organizations here in the Philippines as well as
        through blogs.
      </p>
      <br />
      <p>
        Because of my passion for sharing and contributing to the community, I
        was nominated and received an award as one of the Microsoft MVPs (Most
        Valuable Professionals). I'm still an active Microsoft MVP in the
        Philippines until now.
      </p>
      <div class="columns">
        <div class="column is-10">
          <blockquote>
            This award is given to exceptional technical community leaders who
            share their remarkable passion, real-world knowledge, and technical
            expertise with others through demonstration of exemplary commitment.
            We appreciate your outstanding contributions in the Visual Studio
            and Development Technologies technical communities during the past
            year."
          </blockquote>
        </div>
        <div class="column is-2 is-center-text">
          <a
            href="https://mvp.microsoft.com/en-us/PublicProfile/5002860"
            target="_blank"
          >
            <img src="../../../assets/mvp-logo.jpg" height="130" width="280" />
          </a>
        </div>
      </div>
      <p>
        In my free time, I usually play logical games, learn new things and
        exploring different things. I like to travel and capture some
        photos/videos. Simply, I'm just a normal adventurer who seeks a lot of
        different adventures.
      </p>
      <p>I'm a simple programmer, but my dreams are extraordinary :)</p>
      <br />
      <div class="columns">
        <div class="column is-4">
          <article class="message is-light">
            <div class="message-header">
              <p>NPM Package</p>
            </div>
            <div class="message-body">
              <a
                href="https://www.npmjs.com/package/check-password-strength"
                target="_blank"
                >check-password-strength</a
              >
              <ul>
                <li>
                  <img
                    src="https://img.shields.io/npm/dt/check-password-strength.svg"
                  />
                </li>
                <li>
                  <img
                    src="https://img.shields.io/npm/dm/check-password-strength.svg"
                  />
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
