import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { blogPostsData } from 'src/app/static/blogpost-summary.data';
const truncate = require('truncate');
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
  postSummaries = blogPostsData;
  truncateData = truncate
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    this.titleService.setTitle('Blog | Mark Deanil Vicente');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Welcome to my blog',
    });
  }
}
