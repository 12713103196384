<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <p>
        In this blog, we’re going to use this feature to seed users & roles
        because we want our ASP.NET Core app to have its default user and role
        data.
      </p>
      <div class="post-step">FTF (First Things First)</div>
      <p>
        Make sure you already setup your ASP.NET Core app identity models and
        your database context (DbContext). In this demo, I’m using ASP.NET Core
        3.0 and it’s ready for user and role seeding.
      </p>
      <div class="post-sub-step">Role Seeding</div>
      <p>
        First, let’s seed roles. Inside the <code>OnModelCreating</code> of your
        DbContext file, add this code.
      </p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p><code>NormalizedName</code> must always be upper case.</p>
      <div class="post-sub-step">User & User Role Seeding</div>
      <p>
        After we create roles, then we can seed a user with a role attach to it.
        Place this code after the code from above.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        As you can see above, we called the <code>PasswordHasher</code> to
        generate an hash password of <code>temporarypass</code>. We also called
        the <code>builder.Entity< UserRole ></code> to assign the specific user
        to a role.
      </p>
      <div class="post-sub-step">Do the migration</div>
      <p>
        One you finish the steps above, you can now do the migrations and
        database update using your command line.
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        As you notice in the snapshot migration file, there’s a
        <code>migrationBuilder.InsertData(...)</code> command based from the
        user and role that we added.
      </p>
      <img
        src="../../../../../../assets/112219/01.png"
        class="img-responsive"
        alt="Seed Users And Roles in ASP.NET Core | Mark Deanil Vicente"
      />
      <p>Lastly, update the database.</p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <img
        src="../../../../../../assets/112219/02.png"
        class="img-responsive"
        alt="Seed Users And Roles in ASP.NET Core | Mark Deanil Vicente"
      />
      <p>That’s it. It’s very simple to seed a users & roles in EF core!</p>
      <p>Related blog post: <a href="/blog/posts/data-seeding-made-easier-in-aspnet-core-app-and-ef-core" target="_blank" style="font-weight: bold;">Data Seeding Made Easier in ASP.NET Core & EF Core</a></p>
      <app-post-footer></app-post-footer>
      <disqus [identifier]="'/' + postHeader.route"> </disqus>
    </div>
  </div>
</div>
