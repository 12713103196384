import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-create-your-first-action-filter-for-your-asp-net-core-application',
  templateUrl: './create-your-first-action-filter-for-your-asp-net-core-application.component.html',
  styleUrls: ['./create-your-first-action-filter-for-your-asp-net-core-application.component.css']
})
export class CreateYourFirstActionFilterForYourAspNetCoreApplicationComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };
  code1 = `using Microsoft.AspNetCore.Mvc;
using Microsoft.AspNetCore.Mvc.Filters;
  
namespace ActionFilterSampApp
{
  public class PersonIdFilter : IAsyncActionFilter
  {
    private readonly RecordDbContext recordDbcontext;
    public PersonIdFilter(RecordDbContext recordDbcontext)
    {
      this.recordDbcontext = recordDbcontext;
    }
    
    public async Task OnActionExecutionAsync(ActionExecutingContext context, ActionExecutionDelegate next)
    {
      try
      {
        if (context.ActionArguments.ContainsKey("id"))
        {
          var id = (int)context.ActionArguments["id"];
          var person = await recordDbcontext.Person.FirstOrDefaultAsync(x => x.PersonId == id);
          if (person == null) { 
            context.Result = new NotFoundResult();
            return;
          }
        }
        await next();
      }
      catch (Exception e)
      {
        context.Result = new BadRequestObjectResult($"Something went wrong. {e.Message}");
        return;
      }
    }
  }
}
`

  code2 = `services.AddScoped<PersonIdFilter>();`

  code3 = `[Route("api/[controller]")]
[ApiController]
public class TestController : ControllerBase
{
  [ServiceFilter(typeof(PersonIdFilter))]
  public async Task<IActionResult> Get(int id)
  {
    // do something...
  }

  [ServiceFilter(typeof(PersonIdFilter))]
  public async Task<IActionResult> Put(int id)
  {
    // do something...
  }
}`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(117);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }


}
