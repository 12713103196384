<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <img
      src="../../../../../../assets/012222/01.jpg"
      title="Create and Publish Your First NPM Package"
      class="img-responsive"
    />
    <div class="post-content-text">
      <p>
        I created my first package in the year 2020. I felt like it's already
        too late to create my own because a lot of NPM packages are already out
        there, but it didn't stop me to at least try to create one. For all the
        packages I initially created so far, one of them seems to at least
        reaches a lot of developers and invited some friendly contributors to
        enhance the existing codes. It is such a privilege that I become part
        of that package and helping those developers who are using our open
        source package.
      </p>
      <p>
        This package is:
        <a
          href="https://www.npmjs.com/package/check-password-strength"
          target="_blank"
          >check-password-strength</a
        >
      </p>

      <ul>
        <li>
          <img
            src="https://img.shields.io/npm/dt/check-password-strength.svg"
          />
        </li>
        <li>
          <img
            src="https://img.shields.io/npm/dm/check-password-strength.svg"
          />
        </li>
      </ul>
      <p>
        Enough intro, so let's get started on how to create and publish your
        first NPM package.
      </p>
      <div class="post-step">Steps on Creating and Publishing NPM Package.</div>
      <div class="post-sub-step">
        #1. Register an account (If you don't have one yet)
        <p>
          <a href="https://www.npmjs.com/signup" target="_blank"
            >https://www.npmjs.com/signup</a
          >
        </p>
      </div>
      <div class="post-sub-step">#2. Setup your package locally.</div>
      <p>
        2.1 Run this command on terminal or cmd. <code>npm init</code> and
        provide package description.
      </p>
      <img
        src="../../../../../../assets/012222/02.png"
        title="Create and Publish Your First NPM Package"
        class="img-responsive"
      />
      <p>
        In this example, we are going to use jest to run a test in our basic
        package. so let's install jest <code>npm i jest --save-dev</code>
      </p>
      <p>
        I prepared this sample code already but you can have a reference to
        this.
      </p>
      <img
        src="../../../../../../assets/012222/03.png"
        title="Create and Publish Your First NPM Package"
        class="img-responsive"
      />
      <div class="post-sub-step">#3. Publish the package.</div>
      <p>3.1 Run the <code>npm publish --access=public</code></p>
      <img
        src="../../../../../../assets/012222/03.png"
        title="Create and Publish Your First NPM Package"
        class="img-responsive"
      />
      <p>
        In my case, there's an OTP I setup up under
        <strong>Two Factor Authentication</strong> in my Account Settings
        (https://www.npmjs.com/settings/your_username/profile).
      </p>
      <p>
        Once it's done, you may go to this to view all your packages:
        https://www.npmjs.com/settings/your_username/packages
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
