import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-integrate-speech-to-text-azure-cognitive-services-in-reactjs',
  templateUrl: './integrate-speech-to-text-azure-cognitive-services-in-reactjs.component.html',
  styleUrls: ['./integrate-speech-to-text-azure-cognitive-services-in-reactjs.component.scss']
})
export class IntegrateSpeechToTextAzureCognitiveServicesInReactjsComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `const sdk = require("microsoft-cognitiveservices-speech-sdk");`

  code2 = `const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
const region = "westus2";
const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

// Create the speech recognizer.
let speechRecognizer = new sdk.SpeechRecognizer(speechConfig);`

  code3 = `const test = () => {
    speechRecognizer.recognizeOnceAsync((result: any) => {
      switch (result.reason) {
        case sdk.ResultReason.RecognizedSpeech:
          console.log(result.text)
          break;
        case sdk.ResultReason.NoMatch:
          console.log("NOMATCH: Speech could not be recognized.");
          break;
        case sdk.ResultReason.Canceled:
          const cancellation = sdk.CancellationDetails.fromResult(result);
          console.log(\`CANCELED: Reason=\${cancellation.reason}\`);

          if (cancellation.reason === sdk.CancellationReason.Error) {
            console.log(\`CANCELED: ErrorCode=\${cancellation.ErrorCode}\`);
            console.log(\`CANCELED: ErrorDetails=\${cancellation.errorDetails}\`);
            console.log(
              "CANCELED: Did you set the speech resource key and region values?"
            );
          }
          break;
      }
      speechRecognizer.close();
    });
};`

code4 = `import { useState } from "react";
const sdk = require("microsoft-cognitiveservices-speech-sdk");

function App() {
  const key = "YOUR_KEY_FROM_YOUR_COGNITIVE_SERVICE";
  const region = "westus2";
  const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

  // Create the speech recognizer.
  let speechRecognizer = new sdk.SpeechRecognizer(speechConfig);

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const test = () => {
    setLoading(true);
    speechRecognizer.recognizeOnceAsync((result: any) => {
      switch (result.reason) {
        case sdk.ResultReason.RecognizedSpeech:
          setText(result.text);
          break;
        case sdk.ResultReason.NoMatch:
          console.log("NOMATCH: Speech could not be recognized.");
          break;
        case sdk.ResultReason.Canceled:
          const cancellation = sdk.CancellationDetails.fromResult(result);
          console.log(\`CANCELED: Reason=\${cancellation.reason}\`);

          if (cancellation.reason === sdk.CancellationReason.Error) {
            console.log(\`CANCELED: ErrorCode=\${cancellation.ErrorCode}\`);
            console.log(\`CANCELED: ErrorDetails=\${cancellation.errorDetails}\`);
            console.log(
              "CANCELED: Did you set the speech resource key and region values?"
            );
          }
          break;
      }
      speechRecognizer.close();
      setLoading(false);
    });
  };

  return (
    <div className="App">
      {loading ? (
        "Listening..."
      ) : (
        <button onClick={test}>Run Speech To Text</button>
      )}
      <br />
      <textarea defaultValue={text} />
    </div>
  );
}

export default App;`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(124);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
