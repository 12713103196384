<div class="main-page-content-top-adjust"></div>
<div class="container is-max-desktop container-side-adjust">
  <div class="card">
    <div class="card-content">
      <h1 class="title">Blogs</h1>
      <hr />
      <div class="blog-summary-container">
        <div *ngFor="let summary of postSummaries">
          <div class="columns">
            <div class="column is-2">
              <span class="tag is-info">
                <i class="fas fa-calendar" aria-hidden="true"></i>
                <span class="date-section"></span>
                {{ summary.date }}
              </span>
            </div>
            <div class="column is-10">
              <p class="title is-3">
                <a [routerLink]="['/' + summary.route]">{{ summary.title }}</a>
              </p>
              <p class="subtitle is-5">
                {{ truncateData(summary.subtitle, 150) }}
                <a [routerLink]="['/' + summary.route]">Read more...</a>
              </p>
            </div>
          </div>
          <hr />
        </div>
        <div class="is-center-text">Older posts can be found here in the
          <a href="https://deanilvincent.github.io/blog/" target="_blank">old blog page. </a>
        </div>
      </div>
    </div>
  </div>
</div>