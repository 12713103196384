import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-create-and-publish-your-first-npm-package',
  templateUrl: './create-and-publish-your-first-npm-package.component.html',
  styleUrls: ['./create-and-publish-your-first-npm-package.component.css']
})
export class CreateAndPublishYourFirstNpmPackageComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };


  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(118);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }
}
