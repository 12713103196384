import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost, PartSeries } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-reactjs-msal-with-aspnetcore-to-use-azuread-part2',
  templateUrl: './reactjs-msal-with-aspnetcore-to-use-azuread-part2.component.html',
  styleUrls: ['./reactjs-msal-with-aspnetcore-to-use-azuread-part2.component.css']
})
export class ReactjsMsalWithAspnetcoreToUseAzureadPart2Component implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  partSeries: PartSeries[] | any = []

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  code1 = `using Microsoft.AspNetCore.Authentication.JwtBearer;
using Microsoft.Identity.Web;
...
builder.Services.AddAuthentication(JwtBearerDefaults.AuthenticationScheme)
  .AddMicrosoftIdentityWebApi(builder.Configuration);`

  code2 = `builder.Services.AddCors(options => options.AddPolicy("CorsPolicy", policy =>
{
  policy.AllowAnyHeader().AllowAnyMethod().AllowCredentials().WithOrigins("http://localhost:3000");
}));`

  code3 = `app.UseCors("CorsPolicy");
app.UseAuthentication();
app.UseAuthorization();
`

  code4 = `[RequiredScope(RequiredScopesConfigurationKey = "AzureAd:scopes")]`
  code5 = `[Authorize(Roles = "Admin")]
[HttpGet("employees")] // Add this
public IActionResult GetEmployees()
{
  return Ok(Employees);
}

[Authorize] // Add this
[HttpGet("total-employees")]
public IActionResult TotalEmployees()
{
  return Ok(Employees.Length);
}`


  codeAppSettings = `{
  "AzureAd": {
    "scopes": "api.scope", // CREATED from the App Registration for WebAPI/Expose an API
    "ClientId": "PUT_YOUR_CLIENT_ID_HERE", // Client ID of the web api from App Registration
    "Instance": "https://login.microsoftonline.com",
    "TenantId": "PUT_YOUR_TENANT_ID_HERE" // Tenant ID of the web api from App Registration
  },
  "Logging": {
    "LogLevel": {
      "Default": "Information",
      "Microsoft.AspNetCore": "Warning"
    }
  },
  "AllowedHosts": "*"
  }
`

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(120);
    this.partSeries = this.extractStaticPostsService.getPostDataById(119)?.partSeries;
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
