import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-common-options-in-passing-data-between-components-in-angular',
  templateUrl: './common-options-in-passing-data-between-components-in-angular.component.html',
  styleUrls: ['./common-options-in-passing-data-between-components-in-angular.component.css']
})
export class CommonOptionsInPassingDataBetweenComponentsInAngularComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  code1 = `import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {
  name = "John Doe"
  constructor() { }
  
  ngOnInit() {
  }
  
}`

  code2 = `<h2>Parent Component</h2>
<div style="border: 1px solid black;">
  <app-child [nameFromParent]="name"></app-child>
</div>
`

  code3 = `import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.css']
})
export class ChildComponent implements OnInit {
  @Input() nameFromParent = ""
  constructor() { }
  
  ngOnInit() {
  }
  
}`

  code4 = `<h3>I'm Child Component</h3>
<p>{{nameFromParent}}</p>`

  code5 = `<h3>I'm Child Component</h3>
<p>{{nameFromParent}}</p>
<button (click)="updateParentName()">Update name</button>`

  code6 = `import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-child',
    templateUrl: './child.component.html',
    styleUrls: ['./child.component.css']
})
export class ChildComponent implements OnInit {
  @Input() nameFromParent = ""
  @Output() updateNameFromChild = new EventEmitter()
  constructor() { }
  
  ngOnInit() {
  }
  
  updateParentName() {
    this.updateNameFromChild.emit('John Doe Jr.')
  }
  
}`

  code7 = `<h2>Parent Component</h2>
{{name}}
<div style="border: 1px solid black;">
  <app-child (updateNameFromChild)="updateName($event)" [nameFromParent]="name"></app-child>
</div>`

  code8 = `import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {
  name = "John Doe"
  constructor() { }

  ngOnInit() {
  }

  updateName(e: any) {
    this.name = e
  }
}
`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(116);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
