<h4 class="title is-4 is-center-text">My Personal Quotes</h4>
<div class="tile is-ancestor">
  <div class="tile is-vertical is-8">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-success is-light">
          <blockquote>
            Success is like climbing a ladder. It's simple, you need to take an
            extra effort. ;)
          </blockquote>
        </article>
        <article class="tile is-child notification is-warning is-light">
          <blockquote>
            "Knowing your career path is like knowing who will be your wife or
            husband. You should think many times before you decide."
          </blockquote>
        </article>
      </div>
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-primary is-light">
          <blockquote>
            "Dreaming is the first step, don't stay on the first step, but
            instead innovate yourself."
          </blockquote>
        </article>
        <article class="tile is-child notification is-info is-light">
          <blockquote>
            "Test yourself if you're ready or not. There's a time when that you
            have to walk, run or even leap, but it all comes with decision and
            perfect timing."
          </blockquote>
        </article>
      </div>
    </div>
    <div class="tile is-parent is-vertical">
      <article class="tile is-child notification is-danger is-light">
        <blockquote>
          "Constructive and Destructive Criticisms. What we usually pick? As a
          leader or as a follower, we should remember that every word we say in
          criticism manner, could be constructive or destructive effect on other
          people"
        </blockquote>
      </article>
      <article class="tile is-child notification is-light">
        <blockquote>
          "It doesn't matter how many times you fall and face failures, as long
          as your focus on achieving your goals then you'll have it. In the end,
          when you look back at those times, you'll realize the art of learning
          through experiences"
        </blockquote>
      </article>
    </div>
  </div>
  <div class="tile is-parent is-vertical">
    <article class="tile is-child notification is-warning is-light">
      <blockquote>
        "Letting the negative thoughts play around your mind is like a small
        fire waiting to start a wild fire."
      </blockquote>
    </article>
    <article class="tile is-child notification is-success is-light">
      <blockquote>
        "Defining success is not just theoretically but strategically"
      </blockquote>
    </article>
  </div>
  <div class="tile is-parent is-vertical">
    <article class="tile is-child notification is-danger is-light">
      <blockquote>
        "Treasure can be stolen, fame can be disappeared, but having a good
        legacy to someone, is more valuable than any precious things on earth."
      </blockquote>
    </article>
    <article class="tile is-child notification is-info is-light">
      <blockquote>
        "When arts take your mind, your mind will compose a lot of masterpiece."
      </blockquote>
    </article>
  </div>
</div>

<div class="tile is-ancestor">
  <div class="tile is-parent is-vertical">
    <article class="tile is-child notification is-primary is-light">
      <blockquote>"Appreciate the art of program execution."</blockquote>
    </article>
    <article class="tile is-child notification is-warning is-light">
      <blockquote>"Learning is investing not wasting"</blockquote>
    </article>
  </div>
  <div class="tile is-parent is-vertical">
    <article class="tile is-child notification is-danger is-light">
      <blockquote>
        "Defeats and disappointments are exercised to have more patience and
        perseverance."
      </blockquote>
    </article>
    <article class="tile is-child notification is-info is-light">
      <blockquote>
        "Words are powerful, it can express love or hate, lift or let somebody
        down, even build or destroy empires."
      </blockquote>
    </article>
  </div>
  <div class="tile is-vertical is-8">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-primary is-light">
          <blockquote>
            "Opportunities are like some people, they come and go. Accept the
            fact and look for a right one."
          </blockquote>
        </article>
        <article class="tile is-child notification is-warning is-light">
          <blockquote>
            "Creating a software is like a jigsaw puzzle. It’s piece by piece
            procedure."
          </blockquote>
        </article>
      </div>
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-light">
          <blockquote>
            "Don't get upset if things don't work the way you wanted. Keeping
            yourself peaceful while waiting, is the right attitude."
          </blockquote>
        </article>
        <article class="tile is-child notification is-info is-light">
          <blockquote>
            "Never underestimate the power of your "YES" and "NO". It holds your
            future."
          </blockquote>
        </article>
      </div>
    </div>
    <div class="tile is-parent is-vertical">
      <article class="tile is-child notification is-light">
        <blockquote>
          "Do not despise the small and humble beginning because it is where you
          can make the significant difference."
        </blockquote>
      </article>
      <article class="tile is-child notification is-danger is-light">
        <blockquote>
          "Snail Principle: Being slow is not the real burden if you have the
          real motivation to stay on the track of moving on."
        </blockquote>
      </article>
    </div>
  </div>
</div>
