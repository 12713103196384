import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost, PartSeries } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-reactjs-msal-with-aspnetcore-to-use-azuread-part3',
  templateUrl: './reactjs-msal-with-aspnetcore-to-use-azuread-part3.component.html',
  styleUrls: ['./reactjs-msal-with-aspnetcore-to-use-azuread-part3.component.css']
})
export class ReactjsMsalWithAspnetcoreToUseAzureadPart3Component implements OnInit {

  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  partSeries: PartSeries[] | any = []

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }

  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(121);
    this.partSeries = this.extractStaticPostsService.getPostDataById(119)?.partSeries;
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

  code1 = `import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: "PUT_YOUR_CLIENT_ID_HERE", // Client ID 
      authority: 'https://login.microsoftonline.com/PUT_YOUR_TENANT_ID_HERE', // Tenant ID of the React.JS App Registration
      redirectUri: "http://localhost:3000/",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: ["api://PUT_YOUR_WEBAPI_SCOPE_HERE/api.scope"],
};`


  code2 = `import React from "react";
import ReactDOM from "react-dom";
import App from "./App.tsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginApiRequest } from "./authConfig";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import 'bulma/css/bulma.min.css';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

axios.defaults.baseURL = "https://localhost:7002/api/";
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    });
    response.headers.common.Authorization = \`Bearer \${ msalResponse.accessToken }\`;
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);
/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
`

code3 = `import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginApiRequest).catch((e) => {
      console.log(e);
    });
  };
  return <button onClick={handleLogin}>Sign In</button>;
};
`

code4 = `import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  return <button onClick={handleLogout}>Sign In</button>;
};
`

code5 = `import axios from "axios";
import { useState } from "react";
export const Employee = () => {
  const [employees, setEmployees] = useState<string[]>([]);

  const getEmployees = () => {
    axios
      .get("employees/")
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alert("Your access is not allowed.");
        }
      });
  };

  const getTotalEmployees = () => {
    axios
      .get("employees/total-employees")
      .then((response) => {
        alert(\`The total employees: \${response.data}\`);
      })
      .catch((error) => {
        if (error.response.statusCode === 401) {
          alert("Unauthorized");
        }
      });
  };

  return (
    <div>
      <button className="button is-success" onClick={getEmployees}>
        Get Employees
      </button>
      <button className="button" onClick={getTotalEmployees}>
        Get Total Employees
      </button>
      <ol>
        {employees.map((d: string, index: number) => {
          return <li key={index}>{d}</li>;
        })}
      </ol>
    </div>
  );
};
`


code7 = `import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Employee } from "./components/Employee";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";

function App() {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <AuthenticatedTemplate>
                <SignOutButton />
                <Employee />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <SignInButton />
                <h4 className="title is-4">
                  Please sign in to view employee info.
                </h4>
              </UnauthenticatedTemplate>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
`
}