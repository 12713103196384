<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">FTF (First Things First)</div>
      <p>
        If you are using Windows Server, make sure to check you include the
        “IIS” in the Server Roles. Let’s get started.
      </p>
      <div class="post-step">1.0 Build your Vue.JS App</div>
      <p>1.1 Open the terminal and execute the command</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <img
        src="../../../../../../assets/071419/01.jfif"
        class="img-responsive"
        alt="Host Vue.JS on IIS | Mark Deanil Vicente"
      />
      <p>
        If building is successfully, you can find the folder with a name “dist”.
      </p>
      <p>
        1.2 Copy the dist folder content and move it in a folder in Drive C:
      </p>
      <p>
        In my example, I created a folder with a name “myvueapp” in the path of
        C:\inetpub\wwwroot and copy and paste all of the files and folders from
        the dist folder.
      </p>
      <img
        src="../../../../../../assets/071419/02.jfif"
        class="img-responsive"
        alt="Host Vue.JS on IIS | Mark Deanil Vicente"
      />
      <div class="post-step">2.0 Create web.config file</div>
      <p>
        2.1 Create a web.config file inside the “myvueapp” and paste these
        codes:
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>2.2 Save the file.</p>
      <div class="post-step">3.0 Create a website on IIS</div>
      <p>
        3.1 Open the IIS app and create a new website under the “Sites” option
        and name it whatever you prefer. In my example, I name it as
        “myvuewebsite”. Locate the path of “myvueapp” and specify the specific
        port that you want.
      </p>
      <img
        src="../../../../../../assets/071419/03.jfif"
        class="img-responsive"
        alt="Host Vue.JS on IIS | Mark Deanil Vicente"
      />
      <p>3.2 Click “Ok” when you are done.</p>
      <div class="post-step">4.0 Running the app</div>
      <p>
        4.1 On the right option, you will find the “Browse …” with a port
        number. Click and it will open the default browser.
      </p>
      <img
        src="../../../../../../assets/071419/04.jfif"
        class="img-responsive"
        alt="Host Vue.JS on IIS | Mark Deanil Vicente"
      />
      <img
        src="../../../../../../assets/071419/05.jfif"
        class="img-responsive"
        alt="Host Vue.JS on IIS | Mark Deanil Vicente"
      />
      <p>Cool! Now you have Vue.JS app running on IIS.</p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>
