import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BlogPost } from 'src/app/models/blogpost.model';
import { ExtractStaticPostsDataService } from 'src/app/services/extract-static-posts-data.service';

@Component({
  selector: 'app-analyze-image-in-reactjs-using-azure-computer-vision',
  templateUrl: './analyze-image-in-reactjs-using-azure-computer-vision.component.html',
  styleUrls: ['./analyze-image-in-reactjs-using-azure-computer-vision.component.css']
})
export class AnalyzeImageInReactjsUsingAzureComputerVisionComponent implements OnInit {
  postHeader: BlogPost = {
    title: '',
    subtitle: '',
    date: '',
    tag: '',
    route: '',
    id: 0,
    metaTag: '',
  };

  altImage = "Analyze Image in React.JS using Azure Computer Vision - dnilvincent.net"

  code1 = `const key = "_YOUR_ENDPOINT_KEY";
const endpoint = "_YOUR_AZURECOMPUTERSERVICE_ENDPOINT_/vision/v3.2/analyze?";`

  code2 = `const analyzeImage = () => {
  let data = new FormData();
  data.append("blob", image, "file.png");

  const params = new URLSearchParams({
    // "Categories,Description,Color",
    visualFeatures: "Tags",
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Ocp-Apim-Subscription-Key": key,
    },
  };

  axios.post(\`$\{endpoint}\${params}\`, data, config).then((response) => {
    // response here
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    // do something after the request is finished
  });
};`

  code3 = `// Format tags for display
const formatTags = (tags: any) => tags.map((tag: any) => \`\${tag.name} (\${tag.confidence.toFixed(2) * 100}%)\`).join(", ")`

  code4 = `import { useState } from "react";
import axios from "axios";

function App() {
  const key = "_YOUR_ENDPOINT_KEY";
  const endpoint = "_YOUR_AZURECOMPUTERSERVICE_ENDPOINT_/vision/v3.2/analyze?";

  const [loading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [result, setResult] = useState<string>();

  // Format tags for display
  const formatTags = (tags: any) => tags.map((tag: any) => \`\${tag.name} (\${tag.confidence.toFixed(2) * 100}%)\`).join(", ")

  const analyzeImage = () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("blob", image, "file.png");

    const params = new URLSearchParams({
      // "Categories,Description,Color",
      visualFeatures: "Tags",
    }).toString();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Ocp-Apim-Subscription-Key": key,
      },
    };
    axios.post(\`\${endpoint}\${params}\`, data, config).then((response) => {
        setResult(\`Extract tags: \${formatTags(response.data.tags)}\`);
      }).catch((error) => {
          console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeImage = (e: any) => { if (e.target.files && e.target.files.length > 0) setImage(e.target.files[0]);}

  return (
    <>
      <input type="file" accept="image/*" onChange={(e) => handleChangeImage(e)} />
      {loading ? ( "Analyzing...") : (<button onClick={analyzeImage}>Analyze Image</button>)}
      <br />
      {image ? (<img src={URL.createObjectURL(image)} alt="preview" width={250} />) : null}
      {result ? (<><h3>Results:</h3><p>{result}</p></>) : null}
    </>
  );
}

export default App;`

  constructor(private extractStaticPostsService: ExtractStaticPostsDataService,
    private titleService: Title,
    private metaTagService: Meta) {
    this.getBlogPostHeaderData()
  }
  ngOnInit() {
    this.titleService.setTitle(this.postHeader.title);
    this.metaTagService.updateTag({
      name: 'title',
      content: `${this.postHeader.title} | Mark Deanil Vicente` ?? '',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.postHeader.metaKeywords ?? '',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: this.postHeader.metaDesc ?? '',
    });
  }

  getBlogPostHeaderData() {
    const data = this.extractStaticPostsService.getPostDataById(125);
    if (data) {
      this.postHeader.title = data.title;
      this.postHeader.subtitle = data.subtitle;
      this.postHeader.route = data.route;
      this.postHeader.date = data.date;
      this.postHeader.metaDesc = data.subtitle;
      this.postHeader.metaKeywords = data.metaKeywords;
    }
  }

}
