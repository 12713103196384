<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/blogphotos/integrate-chatgp-vue-1.png"
        alt="Mark Deanil Vicente - Integrate ChatGPT to Vue.JS"
        class="img-responsive"
      />
      <div class="post-step">Introduction</div>
      <p>
        <a href="https://openai.com/blog/chatgpt" href="_blank">ChatGPT</a> is
        an AI chatbot developed by
        <a href="https://en.wikipedia.org/wiki/OpenAI" target="_blank">OpenAI</a
        >. Its initial released was in November 2022 and since then, it helped a
        lot of people answering their questions, giving procedures, writing,
        summarization, translation, conversatoin and much more. It offers a very
        wide of range of datasets and it's consider to be the best overall AI
        chatbot by other sources.
      </p>
      <p>
        On this blog, our is aim to integrate it to our Vue.JS application send
        a question request and get a response from the actual OpenAI endpoint.
        From this, you'll have a basic idea how would you use it for your future
        projects or include it to your next app feature.
      </p>
      <div class="post-step">FTF (First Things First)</div>
      <div class="post-sub-step">Prerequisites</div>
      <p>1. Make sure you already installed Node.js</p>
      <p>2. Installed Visual Studio Code or other text editor or IDE.</p>
      <div class="post-step">1. Create Vue.JS Project using Vite</div>
      <p>
        In case you're using other ways to install Vue.js, you can skip this one
        otherwise, create a Vue.JS template using Vite
      </p>
      <p>
        1.1 In this example, I'm using Vite.js to scaffold our vite project and
        choose the Vue.js template.
        <code>npm create vite@latest</code>
      </p>
      <br />
      <img
        src="../../../../../../assets/blogphotos/integrate-chatgp-vue-2.png"
        alt="Mark Deanil Vicente - Integrate ChatGPT to Vue.JS"
        class="img-responsive"
      />
      <div class="post-step">2. Install OpenAI Package in Vue.JS App</div>
      <p>2.1 <code>npm i openai</code></p>
      <a
        href="https://www.npmjs.com/package/openai"
        target="_blank"
        >https://www.npmjs.com/package/openai</a
      >
      <div class="post-step">3. Generate API key from your OpenAI account.</div>
      <p>
        Make sure you register and login first on the
        <a
          href="https://platform.openai.com/"
          target="_blank"
          rel="noopener noreferrer"
          >OpenAI platform</a
        >
        website in order to access your account and generate your own keys.
      </p>
      <p>
        3.1 Once you login your account, go to this
        <a
          href="https://platform.openai.com/account/api-keys"
          target="_blank"
          rel="noopener noreferrer"
          >link
        </a>
        or on the right menu, under your account, choose the "View API Keys"
      </p>
      <p>3.2 Create a secret key.</p>

      <div class="post-step">4. Configure the Vue.JS App</div>
      <p>
        On my sample, I'm using the Vue.JS with Composition API project
        template. For this demo, I'm overwriting the <code>App.vue</code> file.
      </p>
      <p>4.1 Import the package below.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>4.2 Create a configuration and attach it to OpenAIApi instance</p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        4.3 Create the function that makes a request to the OpenAI API endpoint
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <br />
      <ul>
        <li>
          <strong>model</strong> : ID of the model to use. You can use the
          <a href="https://platform.openai.com/docs/api-reference/models/list"
            >List models</a
          >
          API to see all of the available models, or see the
          <a href="https://platform.openai.com/docs/models/overview"
            >Model overview</a
          >
          for descriptions of them.
        </li>
        <li>
          <strong>prompt</strong> : The prompt(s) to generate completions for,
          encoded as a string, array of strings, array of tokens, or array of
          token arrays. (<a
            href="https://platform.openai.com/docs/api-reference/completions/create#completions/create-prompt"
            target="_blank"
            rel="noopener noreferrer"
            >Source</a
          >)
        </li>
        <li>
          <strong>max_tokens</strong> : The maximum number of tokens to generate
          in the completion. (<a
            href="https://platform.openai.com/docs/api-reference/completions/create#completions/create-max_tokens"
            target="_blank"
            rel="noopener noreferrer"
            >Source</a
          >)
        </li>
      </ul>
      <p>
        To learn more about the other request parameters available, you can
        visit this
        <a
          href="https://platform.openai.com/docs/api-reference/completions/create"
          target="_blank"
          rel="noopener noreferrer"
          >Link</a
        >
      </p>
      <p>4.4 Create a basic template</p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>

      <img src="https://user-images.githubusercontent.com/10904957/235312706-210a8171-4353-4616-b905-dcc7c9af92dd.gif" alt="ChatGPT with Vue.js" />

      <p>Here's the complete source code:</p>
      <pre>
        <code [highlight]="code5"></code>
      </pre>
      <div class="post-step">Additional Info</div>
      <p>
        That's it! I hope this blog help you how to use ChatGPT OpenAI. You can
        read the official documentation from OpenAI API reference to learn more.
        Happy coding!
        <a href="https://platform.openai.com/docs/api-reference" target="_blank"
          >ChatGPT API reference
        </a>
      </p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
