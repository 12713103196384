<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <div class="post-step">What is exactly PostgreSQL?</div>
      <p>
        PostgreSQL is a powerful, open source object-relational database system
        that uses and extends the SQL language combined with many features that
        safely store and scale the most complicated data workloads.
        <a href="https://www.postgresql.org/about/" target="_blank"
          >postgresql.org/about</a
        >
      </p>
      <p>
        The good thing is, we can easily use this as our database for our
        ASP.NET Core Web App.
      </p>
      <p>So enough intro, let’s get started.</p>
      <div class="post-step">FTF (First Things First)</div>
      <p>
        Make sure you already downloaded and installed PostgreSQL in your
        machine. If you haven’t installed yet, you can visit this link
        <a
          href="http://www.postgresqltutorial.com/install-postgresql/"
          target="_blank"
          >http://www.postgresqltutorial.com/install-postgresql/.</a
        >
      </p>
      <div class="post-step">1.0 Install the Package</div>
      <p>
        In this sample, I’m using ASP.NET Core Web API project but the procedure
        can be implemented in ASP.NET Core MVC.
      </p>
      <p>
        1.1 Open the terminal and execute the command or open Nuget Package
        Manager. Look for this package.
      </p>
      <code>Npgsql.EntityFrameworkCore.PostgreSQL</code>
      <img
        src="../../../../../../assets/092719/01.png"
        class="img-responsive"
        alt="Mark Deanil Vicente | Blog"
      />
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        You can find different versions
        <a
          href="https://www.nuget.org/packages/Npgsql.EntityFrameworkCore.PostgreSQL"
          target="_blank"
          >here</a
        >
        that fits on your .net core version.
      </p>
      <div class="post-step">2.0 Setup connection string.</div>
      <p>
        Locate your the method that implements
        <code>DbContextOptionsBuilder optionsBuilder</code> and update your
        connection string similar to this. Normally you can find them in your
        Startup.cs or in your DbContext file.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        The example above is only a standard connection string, if you want the
        other options, you can find them here.
      </p>
      <div class="post-step">3.0 Execute database migrations.</div>
      <p>
        One you finish the steps above, you can now do the migrations and
        database update using your command line.
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>That’s it. It’s very simple to setup!</p>
    </div>
    <app-post-footer></app-post-footer>
    <disqus [identifier]="'/' + postHeader.route"> </disqus>
  </div>
</div>
