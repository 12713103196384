<section class="hero is-medium is-light is-bold">
  <div class="hero-body animated-bg">
    <div class="container">
      <div class="hero-container">
        <div class="hero-left-content">
          <h1 class="title welcome-text">Hi, I'm Deanil Vicente!</h1>
          <h2 class="subtitle welcome-text">
            <i>Welcome to my blog!</i>
          </h2>
        </div>
        <div class="hero-right-content">
          <object
            width="450"
            style="float: right"
            type="image/svg+xml"
            data="../../../assets/svg-robot.svg"
          ></object>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container container-side-adjust">
  <div class="content-top-adjust"></div>
  <app-about-me></app-about-me>
  <hr />
  <app-tiles></app-tiles>
  <div class="content-top-adjust"></div>
</div>
