<div class="container is-max-desktop container-side-adjust">
  <app-post-header
    [title]="postHeader.title"
    [subtitle]="postHeader.subtitle"
    [date]="postHeader.date"
    [tag]="postHeader.tag"
  ></app-post-header>
  <div class="post-content-container">
    <div class="post-content-text">
      <img
        src="../../../../../../assets/92922/01.jpg"
        [alt]="altImage"
        class="img-responsive"
      />
      <div class="post-step">Introduction</div>
      <p>
        On my previous blog:
        <a
          href="https://blog.dnilvincent.net/blog/posts/analyze-image-in-reactjs-using-azure-computer-vision"
          target="_blank"
          >Analyze Image in React.JS using Azure Computer Vision</a
        >
        , I provided an introduction about Computer Vision and instructions on
        setting up the Azure Portal. Feel free to open the link and follow the
        step 1. The main idea of this blog is to integrate Azure Computer Vision
        to your Vue.JS.
      </p>
      <div class="post-step">1. Install Axios (Optional)</div>
      <p>
        2.1 <code>npm i axios</code> a promise-based HTTP Client for our
        application.
      </p>

      <div class="post-step">2. Configure the Vue.JS App</div>
      <p>
        For this demo, I'm overwriting the <code>App.vue</code> file and we're
        using Composition API.
      </p>
      <p>3.1 Import <code>axios</code> on your file.</p>
      <p>3.2 Declare the keys inside the Vue javascript setup.</p>
      <pre>
        <code [highlight]="code1"></code>
      </pre>
      <p>
        Note: Put your keys to a secure key storage like
        <a href="https://azure.microsoft.com/en-us/products/key-vault/"
          >Azure Key Vault</a
        >
        or use a backend server where you can put and hide the keys to an environment
        file.
      </p>
      <p>
        3.3 Create a method that will analyze the image and that will call to
        our endpoint.
      </p>
      <pre>
        <code [highlight]="code2"></code>
      </pre>
      <p>
        We have other options for the visual feature that we can use like
        "Categories", "Description" and "Color"
      </p>
      <p>
        What happens here is that we're attaching the blob image from input file
        to the
        <code>FormData</code> then send a request to the Azure Computer Vision
        endpoint. Azure Computer Vision does its magic with its powerful
        algorithm.
      </p>
      <p>
        The <code>Ocp-Apim-Subscription-Key</code> is where we attach the key
        that we declared above.
      </p>
      <p>
        3.4 Since we're using <code>Tags</code> as our Visual Feature, we can
        display our response to a friendly result so if we view it, we'll get a
        readable data.
      </p>
      <pre>
        <code [highlight]="code3"></code>
      </pre>
      <p>
        Here's the final code that you can use on your local. (Don't mind the
        skeleton screens)
      </p>
      <pre>
        <code [highlight]="code4"></code>
      </pre>
      <!-- <img
        src="../../../../../../assets/92922/demo2.gif"
        [alt]="altImage"
        class="img-responsive"
      /> -->
      <p>
        The analyzing could take long depending on how big the file size that
        you want to analyze. Overall, it's a great feature that you can include
        to your Vue.JS Application.
      </p>
      <p>
        You can read more about the Computer Vision from official
        <a
          href="https://learn.microsoft.com/en-us/azure/cognitive-services/computer-vision/"
          target="_blank"
          >Microsoft Learn</a
        >
      </p>
      <p>That's it! I hope you enjoy this blog. Have a wonderful day!</p>
    </div>
  </div>
  <app-post-footer></app-post-footer>
  <disqus [identifier]="'/' + postHeader.route"> </disqus>
</div>
