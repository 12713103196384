<div class="main-page-content-top-adjust"></div>
<div class="container container-side-adjust">
  <div class="card">
    <div class="card-content">
      <div class="is-center-text">
        <h1 class="title is-1 is-center-text">Page not found!</h1>
        <a class="button is-link is-small is-outlined" [routerLink]="['/']">Go back home</a>
      </div>
      <br />
      <img src="../../../assets/not-found.jpg" class="img-responsive" />
    </div>
  </div>
</div>
