import { BlogPost } from '../models/blogpost.model';

export const blogPostsData: BlogPost[] = [
  {
    title: 'Lazy load an image and detect when it\'s is loaded in React.JS',
    subtitle:
      'On this blog, we are going to implement a simple yet very important feature when working with images on React.JS',
    date: 'Feb. 29, 2024',
    route: 'blog/posts/lazy-load-an-image-and-detect-when-its-loaded-in-reactjs',
    metaKeywords:
      'lazy loading of image in React.js, detect when image is laoded in react.js',
    id: 131,
  },
  {
    title: 'Lazy load an image and detect when it\'s is loaded in Vue.JS',
    subtitle:
      'On this blog, we are going to implement a simple yet very important feature when working with images on Vue.JS',
    date: 'Oct. 31, 2023',
    route: 'blog/posts/lazy-load-an-image-and-detect-when-its-loaded-in-vuejs',
    metaKeywords:
      'lazy loading of image in Vue, detect when image is laoded in vue.js',
    id: 130,
  },
  {
    title: 'Add Image Analyzer to your Vue.JS Application using Azure Computer Vision',
    subtitle:
      'On this blog, we\'ll be adding Azure Computer Vision to your Vue.JS application to analyze images and retrieve their information.',
    date: 'Jun. 30, 2023',
    route: 'blog/posts/add-image-analyzer-to-your-vuejs-using-azure-computer-vision',
    metaKeywords:
      'analyze image in vue.js, analyze image using vue.js, inspect image using vue.js, azure computer vision in vue.js',
    id: 129,
  },
  {
    title: 'Integrate ChatGPT with Vue.JS App using OpenAI API',
    subtitle:
      'ChatGPT is very popular. From answering questions, teaching a step-by-step procedures, even telling a jokes, and much more! It is one of the top choices for natural language processing tool. On this blog, we are going to integrate it to our Vue.js. A basic question and answer example but it\'ll give you an idea how would you use it in the future.',
    date: 'Apr. 29, 2023',
    route: 'blog/posts/integrate-chatgpt-with-vuejs-app-using-openai-api',
    metaKeywords:
      'Vue.js, vue.js cognitive services, vue.js speech to text, speech to text in vue.js, azure cognitive services in vue.js',
    id: 128,
  },
  {
    title: 'Add Azure Cognitive Services Speech to Text to your Vue.JS App',
    subtitle:
      'This blog post will help you how to easily integrate speech to text feature from Azure Cognitive Services to your Vue.JS application.',
    date: 'Feb. 28, 2023',
    route: 'blog/posts/add-azure-cognitive-services-speech-to-text-to-your-vuejs-app',
    metaKeywords:
      'Vue.js, vue.js cognitive services, vue.js speech to text, speech to text in vue.js, azure cognitive services in vue.js',
    id: 127,
  },
  {
    title: 'Add Azure Cognitive Services Text to Speech to your Vue.JS App',
    subtitle:
      'This blog post will help you how to easily integrate text to speech feature from Azure Cognitive Services to your Vue.JS application.',
    date: 'Dec. 30, 2022',
    route: 'blog/posts/add-azure-cognitive-services-text-to-speech-to-your-vuejs-app',
    metaKeywords:
      'Vue.js, vue.js cognitive services, vue.js text to speech, text to speech in vue.js, azure cognitive services in vue.js',
    id: 126,
  },
  {
    title: 'Analyze Image in React.JS using Azure Computer Vision',
    subtitle:
      'Nowadays, whether digitally or physically, images or visual representations of things play a big role in our society. As a developer, we may be assigned to a task where we need to analyze images without any hassle. In this blog, we\'ll be using Azure Computer Vision to analyze images and retrieve their information. We\'ll be using React.JS but you can use other supported frameworks or programming languages.',
    date: 'Sep. 30, 2022',
    route: 'blog/posts/analyze-image-in-reactjs-using-azure-computer-vision',
    metaKeywords:
      'analyze image in react.js, analyze image using react.js, inspect image using react.js, azure computer vision in react.js',
    id: 125,
  },
  {
    title: 'Integrate Azure Cognitive Services Speech to Text to your React.JS App',
    subtitle:
      'If Azure Cognitive Services has text to speech, the good thing about it is that it also has speech to text feature. On this blog post, we\'ll incorporate that feature to your React.JS application as well.',
    date: 'Aug. 31, 2022',
    route: 'blog/posts/integrate-azure-cognitive-services-speech-to-text-to-your-reactjs-app',
    metaKeywords:
      'React.js, react.js crud, react.js speech to text, speech to text in react.js, azure cognitive services in react.js',
    id: 124,
  },
  {
    title: 'Integrate Azure Cognitive Services Text to Speech to your React.JS App',
    subtitle:
      'This blog post will help you how to easily integrate text to speech feature from Azure Cognitive Services to your React.JS application.',
    date: 'Jul. 31, 2022',
    route: 'blog/posts/integrate-azure-cognitive-services-text-to-speech-to-your-reactjs-app',
    metaKeywords:
      'React.js, react.js crud, react.js text to speech, text to speech in react.js, azure cognitive services in react.js',
    id: 123,
  },
  {
    title: 'Integrate Redux Toolkit in Dynamic React.JS Typescript App',
    subtitle:
      'On this blog, I\'ll share to your the Redux Toolkit and integrate it to a dynamic React.JS Typepscript app. I will also give you a brief introduction about ReduxToolkit and how powerful this toolset is.',
    date: 'May 31, 2022',
    route: 'blog/posts/integrate-redux-toolkit-in-dynamic-reactjs-typescript-app',
    metaKeywords:
      'React.js, react.js crud, react.js crud reduxtoolkit, reduxtoolkit, create crud in react.js',
    id: 122,
  },
  {
    title: 'React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 3',
    subtitle:
      'This is the part 3 and the last part of this blog series. This is where we setup and configure our frontend React.JS application to properly connect to the secured endpoints we created from the previous part. We\'ll also create and configure the app registration for our React.js in Azure AD.',
    date: 'Mar. 28, 2022',
    route: 'blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part3',
    metaKeywords:
      'React.js msal with asp.net, react.js with msal connect to asp.net core., React.js msal with asp.net core',
    id: 121,
  },
  {
    title: 'React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 2',
    subtitle:
      'This is the part 2 of this blog where we configure and setup our ASP.NET Core Web API. We\'ll install package, add configuration in the appsettings.json, setup the Startup.cs and the controller to properly secure our backend endpoints.',
    date: 'Mar. 27, 2022',
    route: 'blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part2',
    metaKeywords:
      'React.js msal with asp.net, react.js with msal connect to asp.net core., React.js msal with asp.net core',
    id: 120,
  },
  {
    title: 'React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 1',
    subtitle:
      'This is the part 1 of this blog where we configure and setup our Azure AD (Active Directory) App Registration for the web api. We will also work on the app roles that we can associate with the app registration.',
    date: 'Mar. 26, 2022',
    route: 'blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part1',
    metaKeywords:
      'React.js msal with asp.net, react.js with msal connect to asp.net core., React.js msal with asp.net core',
    partSeries: [{
      title: "React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 1",
      url: '/blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part1'
    }, {
      title: "React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 2",
      url: '/blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part2'
    }, {
      title: "React.JS + MSAL with ASP.NET Core to use Azure AD with User & Role - Part 3",
      url: '/blog/posts/reactjs-msal-with-aspnetcore-to-use-azure-ad-part3'
    }],
    id: 119,
  },
  {
    title: 'Create and Publish Your First NPM Package',
    subtitle:
      'As a developer, especially the one who usually uses or downloads packages from NPMJS.COM, have you ever wondered on how NPM packages were created? On this post, I\'ll guide and show you how to create one that might be useful, not only on your needs, but also to the other developers out there.',
    date: 'Jan. 24, 2022',
    route: 'blog/posts/create-and-publish-your-first-npm-package',
    metaKeywords:
      'Create and publish npm package, how to create npm package, how to publish npm package.',
    id: 118,
  },
  {
    title: 'Create your own Action Filter for your ASP.NET Core Application',
    subtitle:
      'Action Filters in ASP.NET Core allow code to run before or after specific stages in the request processing pipeline of the app. On this post, I\'ll guide you how to create your first action filter for your ASP.NET Core Application. ',
    date: 'Nov. 30, 2021',
    route: 'blog/posts/create-your-own-action-filter-for-your-aspnet-core-app',
    metaKeywords:
      'Parent to child in angular, Pass data between components in angular, From Parent to Child data in angular',
    id: 117,
  },
  {
    title: 'Common Options in Passing Data Between Components in Angular - Part 1',
    subtitle:
      'On this post, I\'ll provide the common options in passing data between components. On the part 1 of this blog, I\'ll show you how to parent to child/child to parent using \'Input\' and \'Output\' decorators. And the second part is by using service.',
    date: 'July 31, 2021',
    route: 'blog/posts/common-options-in-passing-data-between-components-in-angular',
    metaKeywords:
      'Parent to child in angular, Pass data between components in angular, From Parent to Child data in angular',
    id: 116,
  },
  {
    title: 'Setup SignalR in ASP.NET Core Application',
    subtitle:
      'SignalR is quite popular in ASP.NET framework. This library allows your ASP.NET server code to send asynchronous notifications to client-side web app. On this post, we\'ll cover how to set it up in your application.',
    date: 'May 30, 2021',
    route: 'blog/posts/setup-signalr-in-aspnet-core-application',
    metaKeywords:
      'SignalR in ASP.NET Core 5, SignalR in ASP.NET Core 3.1, SignalR in ASP.NET Core web api, Setup SignalR in ASP.NET Core, What is SignalR',
    id: 115,
  },
  {
    title: 'Data Seeding Made Easier in ASP.NET Core & EF Core',
    subtitle:
      'On this post, we are going to setup the data seeder in ASP.NET Core App. We will also set the app to automatically run the data seeder and migration upon the start of the application via Program.cs',
    date: 'Mar. 14, 2021',
    route: 'blog/posts/data-seeding-made-easier-in-aspnet-core-app-and-ef-core',
    metaKeywords:
      'Data seed in ASP.NET Core, Data seeding in ASP.NET Core, How to data seeding in ASP.NET Core 5, How to data seeding in ASP.NET Core 3.1, How to data seeding in ASP.NET Core 2.1, Data seeding in ASP.NET Core',
    id: 114,
  },
  {
    title: 'How to Add Live Chat Feature in Vue.JS, React.JS, or Angular?',
    subtitle:
      'In this article, I am going to guide you how to add a chat feature (powered by Chatra) in your Vue.JS, React.JS or Angular site.',
    date: 'Feb. 27, 2021',
    route: 'blog/posts/how-to-add-live-chat-feature-in-vuejs-reactjs-or-angular',
    metaKeywords:
      'live chat in angular, chat in angular,live chat in react.js, chat in react.js, live chat in vue.js, chat in vue.js, How to add chat in angular, how to add chat in Vue.JS, How to add chat in React.JS',
    id: 113,
  },
  {
    title: 'Create Server-Side Pagination in ASP.NET Core App',
    subtitle:
      'This article will help you how to create a simple paginated response from your ASP.NET Core with the use of generic class helper.',
    date: 'Jan. 31, 2021',
    route: 'blog/posts/create-server-side-pagination-in-aspnet-core',
    metaKeywords:
      'Pagination in ASP.NET Core, Pagination, How to create pagination in ASP.NET, pagination in asp.net',
    id: 112,
  },
  {
    title:
      'Create Simple Custom URL Redirection for Vue.JS, React.JS or Angular',
    subtitle:
      'On this blog, we’re going to create our simple custom URL redirection for your javascript application. This is helpful especially if you’re working with session timeout and you want to put the user’s previous url in the login page URL path or in the local storage so when the user has successfully logged in, then it will redirect to the previous page before the session timeout.',
    date: 'Jan. 16, 2021',
    route:
      'blog/posts/create-simple-custom-url-redirection-for-your-javascript-app',
    metaKeywords:
      'URL Redirection, Vue.JS URL Redirection, Angular URL Redirection, React.JS URL Rediction, Javascript URL Redirection, redirect vue.js, redirect react.js, redirect angular, session redirect vue.js, session redirect angular, session redirect react.js',
    id: 111,
  },
  {
    title: 'How to get Enum Display Name in C# .NET',
    subtitle:
      'On this blog, I’ll show you a basic extension method that you can use to get the display name of your enum.',
    date: 'Nov. 30, 2020',
    route: 'blog/posts/how-to-get-enum-display-name-in-csharp-net',
    metaKeywords: 'Value from Enum C#, Get value from C# enum, C# enum value',
    id: 110,
  },
  {
    title: 'How to Deploy Vue.JS App on Azure using Visual Studio Code',
    subtitle:
      'Deploying Vue.JS App made easy using an extension available on Visual Studio Code. On this post, I’ll guide you on how to create your app service on Azure where you will be able to deploy your Vue.JS app in that instance.',
    date: 'Jul. 24, 2020',
    route: 'blog/posts/how-to-deploy-vue-js-app-on-azure-using-vscode',
    metaKeywords: 'Deploy Vue.JS, Vue.JS on Azure, Deploy Vue.JS on Azure',
    id: 109,
  },
  {
    title: 'How to Get the Current Run ID using MLFlow in Azure Databricks',
    subtitle:
      'On this blog post, you will learn how easy to get the run ID in your current Azure Databricks Notebook. This is helpful especially if you want to use that run ID for deployment within the same notebook that you’re working on.',
    date: 'Jun. 26, 2020',
    route:
      'blog/posts/how-to-get-the-current-runid-using-mlflow-in-azure-databricks',
    metaKeywords: 'azure databricks mlflow, how to get runid in azure databricks',
    id: 108,
  },
  {
    title: 'Seed Users & Roles in ASP.NET Core with Entity Framework (EF) Core',
    subtitle:
      'Users & roles seeding in an app is optional but it is one of the important tasks you should consider while building your application authentication. Instead of creating a data via your app form, using data seeding, you can directly populate data upon EF migration. This feature is called “Model seed data” or “Data Seeding” and it’s out since EF Core 2.1.',
    date: 'Nov. 22, 2019',
    route:
      'blog/posts/seed-users-and-roles-in-asp-net-core-with-entity-framework-core',
    metaKeywords:
      'Seed user roles in ASP, Seed role in ASP.NET, Entity Framework Seed',
    id: 107,
  },
  {
    title: 'How to setup PostgreSQL in ASP.NET Core',
    subtitle:
      'If you are looking for free & open-source alternative relational database management system for you application, there are a lot of options out there. But one of my top picks is the PostgreSQL. PostgreSQL is not new in the industry, in fact it has been around for more than two decades and a lot of small, medium to enterprise businesses are using this.',
    date: 'Sep. 27, 2019',
    route: 'blog/posts/how-to-setup-postgresql-in-asp-net-core',
    metaKeywords: 'Setup postgreSQL in ASP.NET Core, PostgreSQL in ASP.NET Core',
    id: 106,
  },
  {
    title: 'Setting up AutoMapper in ASP.NET Core',
    subtitle:
      'Automapper is very useful especially if you want to avoid too much manual mapping from DTO class (Data transfer object) to your Model class vice versa. But what is Automapper?',
    date: 'Sep. 20, 2019',
    route: 'blog/posts/setting-up-automapper-in-aspnet-core',
    metaKeywords: 'Setup Automapper in ASP.NET Core, AutoMapper in ASP.NET Core',
    id: 105,
  },
  {
    title: 'Host Vue.JS App on IIS',
    subtitle:
      'In hosting your vue.js app, you have many options to choose from. You can host on different platforms & services like Github Pages, Gitlab Pages, Netlify etc. or even you can create a Docker container that has deployed Vue.JS inside and run it on different virtual machines (this is another topic). These platforms & services provide easy-to-deploy solution but, what if you are in an environment where all of the web apps should be hosted on IIS? Fortunately, IIS supports an easy solution in running vue.js on it. So, let’s get started.',
    date: 'Jul. 14, 2019',
    route: 'blog/posts/host-vuejs-app-on-iis',
    metaKeywords: 'Host Vue.JS on IIS, how to host vue.js on IIS',
    id: 104,
  },
];
